// @ts-check
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import { ListItemSecondaryAction } from '@material-ui/core';

import IconButton from '@material-ui/core/IconButton/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import LinkIcon from '@material-ui/icons/Link';

import { UpdateWebhooksFormComponent } from './update-webhooks-form-component';
import { Notifications } from '@material-ui/icons';
import { OrderEvents, TripEvents } from '../../../../core/middleware/events';
import { ClientType } from '../../../../core/types/organization';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
  inline: {
    display: 'inline'
  },
  heading: {
    margin: theme.spacing(2)
  },
  fab: {
    width: '100%'
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  }
}));

function Webhooks({ organization }) {
  const classes = useStyles();
  const isLogistics = organization.client_type === ClientType.LOGISTICS;

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <List className={classes.root}>
        <ListItem>
          {/* <ListItemAvatar>
            <Avatar
              alt={`${user.firstname} ${user.lastname}`}
              src={user.image || '/static/images/avatar/1.jpg'}
            />
          </ListItemAvatar> */}
          <ListItemText primary={`Update Webhooks`} secondary={null}></ListItemText>

          <ListItemSecondaryAction>
            {!open && (
              <IconButton edge="end" aria-label="comments" onClick={handleClickOpen}>
                <EditIcon />
              </IconButton>
            )}
            {open && (
              <IconButton edge="end" aria-label="comments" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            )}
          </ListItemSecondaryAction>
        </ListItem>
      </List>

      <List className={classes.root}>
        <ListItem>
          <ListItemAvatar>
            <LinkIcon />
          </ListItemAvatar>

          <ListItemText
            primary={<small>Location webhook</small>}
            secondary={
              organization.webhooks.location ? organization.webhooks.location : 'No URL provided'
            }
          ></ListItemText>
        </ListItem>
      </List>

      <List className={classes.root}>
        <ListItem>
          <ListItemAvatar>
            <LinkIcon />
          </ListItemAvatar>

          <ListItemText
            primary={<small>General webhook</small>}
            secondary={
              organization.webhooks.general ? organization.webhooks.general : 'No URL provided'
            }
          ></ListItemText>
        </ListItem>
      </List>

      {!isLogistics && (
        <List className={classes.root}>
          <ListItem>
            <ListItemAvatar>
              <LinkIcon />
            </ListItemAvatar>

            <ListItemText
              primary={<small>Transaction webhook</small>}
              secondary={
                organization.webhooks.transaction
                  ? organization.webhooks.transaction
                  : 'No URL provided'
              }
            ></ListItemText>
          </ListItem>
        </List>
      )}

      <List className={classes.root}>
        <ListItem>
          <ListItemAvatar>
            <Notifications />
          </ListItemAvatar>
          {isLogistics ? (
            <ListItemText
              primary={<small>Task events</small>}
              secondary={`${Object.values(organization.webhooks.task_events).reduce(
                (a, item) => a + (item === true ? 1 : 0),
                0
              )} / ${Object.keys(organization.webhooks.task_events).length}`}
            ></ListItemText>
          ) : (
            <ListItemText
              primary={<small>Order events</small>}
              secondary={`${Object.values(organization.webhooks.order_events).reduce(
                (a, item) => a + (item === true ? 1 : 0),
                0
              )} / ${Object.keys(organization.webhooks.order_events).length}`}
            ></ListItemText>
          )}

          <ListItemText
            primary={<small>Trip events</small>}
            secondary={`${Object.values(organization.webhooks.trip_events).reduce(
              (a, item) => a + (item === true ? 1 : 0),
              0
            )} / ${Object.keys(organization.webhooks.trip_events).length}`}
          ></ListItemText>

          <ListItemText
            primary={<small>Driver events</small>}
            secondary={`${Object.values(organization.webhooks?.driver_events).reduce(
              (a, item) => a + (item === true ? 1 : 0),
              0
            )} / ${Object.keys(organization.webhooks.driver_events).length}`}
          ></ListItemText>
          {!isLogistics && (
            <>
              <ListItemText
                primary={<small>Wallet events</small>}
                secondary={`${Object.values(organization.webhooks?.wallet_events).reduce(
                  (a, item) => a + (item === true ? 1 : 0),
                  0
                )} / ${Object.keys(organization.webhooks.wallet_events).length}`}
              ></ListItemText>

              <ListItemText
                primary={<small>Transaction events</small>}
                secondary={`${Object.values(organization.webhooks?.transaction_events).reduce(
                  (a, item) => a + (item === true ? 1 : 0),
                  0
                )} / ${Object.keys(organization.webhooks.transaction_events).length}`}
              ></ListItemText>
            </>
          )}
        </ListItem>
      </List>

      {open && (
        <>
          <Typography variant="subtitle2" component="h3" align="center" className={classes.heading}>
            <small>
              <b>Edit Webhooks</b>
            </small>
          </Typography>
        </>
      )}

      {open && (
        <UpdateWebhooksFormComponent
          organization={organization}
          handleClose={handleClose}
          isLogistics={isLogistics}
        />
      )}
    </>
  );
}

export function WebhooksComponent({ organization }) {
  const classes = useStyles();

  return (
    <div>
      <Typography variant="subtitle2" component="h3" align="center" className={classes.heading}>
        <small>
          <b>Webhooks</b>
        </small>
      </Typography>

      <Webhooks organization={organization} />
    </div>
  );
}
