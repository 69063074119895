import { User } from '../../domain/entities/user-entity';
import { UserRepository } from '../../domain/repositories/user-repository';
import { createUser } from '../datasources/create-user';
import { editUser } from '../datasources/edit-user';
import { editUserEmail } from '../datasources/edit-user-email';
import { editUserPassword } from '../datasources/edit-user-password';
import { editUserPhone } from '../datasources/edit-user-phone';
import { editUserScopes } from '../datasources/edit-user-scopes';
import { getUser } from '../datasources/get-user';
import { getUsers } from '../datasources/get-users';
import { grantAdminAccess } from '../datasources/grant-admin-access';
import { grantFleetAccess } from '../datasources/grant-fleet-access';
import { grantHubAccess } from '../datasources/grant-hub-access';
import { grantRegionAccess } from '../datasources/grant-region-access';
import { revokeAdminAccess } from '../datasources/revoke-admin-access';
import { revokeFleetAccess } from '../datasources/revoke-fleet-access';
import { revokeHubAccess } from '../datasources/revoke-hub-access';
import { revokeRegionAccess } from '../datasources/revoke-region-access';
import { uploadUserImage } from '../datasources/upload-user-image';

export class UserRepositoryImpl implements UserRepository {
  async CreateUser(
    firstname: string,
    lastname: string,
    email: string,
    mobile_no: string,
    password: string,
    scopes: string[]
  ): Promise<User> {
    const result = await createUser(firstname, lastname, email, mobile_no, password, scopes);

    return new User(result, result.permissions);
  }

  async EditUser(user_id: string, firstname: string, lastname: string): Promise<User> {
    const result = await editUser(user_id, firstname, lastname);

    return new User(result, result.permissions);
  }
  
  async EditUserScopes(user_id: string, scopes: string[]): Promise<User> {
    const result = await editUserScopes(user_id, scopes);
    return new User(result, result.permissions);
  }


  async EditUserEmail(user_id: string, email: string): Promise<User> {
    const result = await editUserEmail(user_id, email);

    return new User(result, result.permissions);
  }

  async EditUserPhone(user_id: string, mobile_no: string): Promise<User> {
    const result = await editUserPhone(user_id, mobile_no);

    return new User(result, result.permissions);
  }

  async EditUserPassword(user_id: string, password: string): Promise<User> {
    const result = await editUserPassword(user_id, password);

    return new User(result, result.permissions);
  }

  async GetUsers(): Promise<User[]> {
    const result = await getUsers();

    return result.map((user: any) => new User(user));
  }

  async GetUser(user_id: string): Promise<User> {
    const result = await getUser(user_id);

    return new User(result, result.permissions);
  }

  async GrantAdminRights(user_id: string): Promise<User> {
    const result = await grantAdminAccess(user_id);

    return new User(result, result.permissions);
  }

  async RevokeAdminRights(user_id: string): Promise<User> {
    const result = await revokeAdminAccess(user_id);

    return new User(result, result.permissions);
  }

  async GrantFleetAccess(user_id: string): Promise<User> {
    const result = await grantFleetAccess(user_id);

    return new User(result, result.permissions);
  }

  async RevokeFleetAccess(user_id: string): Promise<User> {
    const result = await revokeFleetAccess(user_id);

    return new User(result, result.permissions);
  }

  async GrantHubAccess(user_id: string, hub_id: string): Promise<User> {
    const result = await grantHubAccess(user_id, hub_id);

    return new User(result, result.permissions);
  }

  async RevokeHubAccess(user_id: string, hub_id: string): Promise<User> {
    const result = await revokeHubAccess(user_id, hub_id);

    return new User(result, result.permissions);
  }

  async GrantRegionAccess(user_id: string, region_id: string): Promise<User> {
    const result = await grantRegionAccess(user_id, region_id);

    return new User(result, result.permissions);
  }

  async RevokeRegionAccess(user_id: string, region_id: string): Promise<User> {
    const result = await revokeRegionAccess(user_id, region_id);

    return new User(result, result.permissions);
  }

  async UploadUserImage(client_id: string, user_id: string, file: any): Promise<User> {
    await uploadUserImage(client_id, user_id, file);
    const result = await getUser(user_id);

    return new User(result, result.permissions);
  }
}
