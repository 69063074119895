import fetch from 'isomorphic-fetch';
import { config } from '../../../../core/config/config';
import store from '../../../../core/redux/store';

/**
 * Update hub cross clustering
 * @function
 * @param {string} hub_id - The hub ID
 * @param {boolean} enable - Whether to enable or disable cross clustering
 * @throws {Error}
 * @returns {Promise<any>}
 */
export const updateHubCrossClustering = async (hub_id: string, enable: boolean): Promise<any> => {
  try {
    const state = store.getState();
    const token = state.token.token;

    const uri = enable
      ? `${config.uri.base_url}/hubs/grant-cross-clustering`
      : `${config.uri.base_url}/hubs/revoke-cross-clustering`;

    const options = {
      method: 'POST',
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify({ hub_id })
    };

    const response = await fetch(uri, options);
    const result = await response.json();

    if (!response.ok) {
      throw new Error(result.message || 'Something went wrong');
    }

    return result;
  } catch (err) {
    if (err instanceof Error) {
      throw new Error(
        `Error ${enable ? 'enabling' : 'disabling'} cross clustering: ${err.message}`
      );
    } else {
      throw new Error(`Error ${enable ? 'enabling' : 'disabling'} cross clustering: ${err}`);
    }
  }
};
