import { ORDER_HISTORY_TYPE } from './settings-enum';

class Pooling {
  enabled: boolean;
  algorithm: string;

  constructor(enabled: boolean, algorithm: string) {
    this.enabled = enabled;
    this.algorithm = algorithm;
  }
}

class ClusterLocking {
  strategy: string;
  time: number;
  collection_time_range: number;

  constructor(strategy: string, time: number = 0, collection_time_range: number = 0) {
    this.strategy = strategy;
    this.time = time;
    this.collection_time_range = collection_time_range;
  }
}

class Cluster {
  enabled: boolean;
  distance: number;
  size: number;
  lock: ClusterLocking;

  constructor(enabled: boolean, distance: number, size: number, lock: any) {
    this.enabled = enabled;
    this.distance = distance;
    this.size = size;
    this.lock = new ClusterLocking(lock.strategy, lock.time, lock.collection_time_range);
  }
}

class Geofence {
  enabled: boolean;
  branch_distance: number;
  customer_distance: number;
  customer_arrived_distance: number;
  branch_distance_enabled: boolean;
  customer_distance_enabled: boolean;
  customer_arrived_enabled: boolean;
  end_trip_enabled: boolean;
  end_trip_distance: number;

  constructor(
    enabled: boolean,
    branch_distance: number,
    customer_distance: number,
    customer_arrived_distance: number,
    branch_distance_enabled: boolean,
    customer_distance_enabled: boolean,
    customer_arrived_enabled: boolean,
    end_trip_enabled: boolean,
    end_trip_distance: number
  ) {
    this.enabled = enabled;
    this.branch_distance = branch_distance;
    this.customer_distance = customer_distance;
    this.customer_arrived_distance = customer_arrived_distance;
    this.branch_distance_enabled = branch_distance_enabled;
    this.customer_distance_enabled = customer_distance_enabled;
    this.customer_arrived_enabled = customer_arrived_enabled;
    this.end_trip_enabled = end_trip_enabled;
    this.end_trip_distance = end_trip_distance;
  }
}

class PoolingGeofence {
  enabled: boolean;
  priority_distance: number;
  maximum_distance: number;

  constructor(enabled: boolean, branch_distance: number, customer_distance: number) {
    this.enabled = enabled;
    this.priority_distance = branch_distance;
    this.maximum_distance = customer_distance;
  }
}

class RecipientName {
  enabled: boolean;
  selected: string[];

  constructor(enabled: boolean, selected: string[]) {
    this.enabled = enabled;
    this.selected = selected;
  }
}

class ROStartEndLocations {
  start_at_branch: boolean;
  end_at_branch: boolean;

  constructor(route_optimisation: any) {
    this.start_at_branch = route_optimisation?.ro_start_end_locations?.start_at_branch || true;
    this.end_at_branch = route_optimisation?.ro_start_end_locations?.end_at_branch || false;
  }
}

class RouteOptimisation {
  priority: string;
  ro_start_end_locations: ROStartEndLocations;

  constructor(priority: string, ro_start_end_locations: ROStartEndLocations) {
    this.priority = priority;
    this.ro_start_end_locations = ro_start_end_locations;
  }
}

class TripSequencing {
  enabled: boolean;

  constructor(enabled: boolean) {
    this.enabled = enabled;
  }
}

class TerminateOrders {
  enabled: boolean;
  max_age: number;

  constructor(enabled: boolean, max_age: number) {
    this.enabled = enabled;
    this.max_age = max_age;
  }
}

class OrderHistory {
  hours: number;
  type: ORDER_HISTORY_TYPE;

  constructor(order_history: any) {
    this.hours = order_history?.hours || 168;
    this.type = order_history?.type ?? ORDER_HISTORY_TYPE.CREATED_AT;
  }
}

class CanCreateTrip {
  enabled: boolean = false;

  constructor(enabled: boolean) {
    this.enabled = enabled;
  }
}

class CanRetryAbandoned {
  enabled: boolean = false;

  constructor(enabled: boolean) {
    this.enabled = enabled;
  }
}

class AutomaticReturns {
  enabled: boolean;

  constructor(automatic_returns: any) {
    this.enabled = automatic_returns?.enabled || false;
  }
}
class GenericSetting {
  enabled: boolean;

  constructor(setting: any) {
    this.enabled = setting?.enabled || false;
  }
}

class OrderNearby {
  enabled: boolean;
  radius: number;

  constructor(enabled: boolean, distance: number) {
    this.enabled = enabled;
    this.radius = distance;
  }
}

interface CrossClustering {
  enabled: boolean;
}

class Hub {
  cross_clustering: CrossClustering;

  constructor(cross_clustering: { enabled: boolean }) {
    this.cross_clustering = { enabled: cross_clustering.enabled };
  }
}

export interface IHideTripCost {
  enabled: boolean;
  branch_ids: string[];
}

class HideTripCost implements IHideTripCost {
  enabled: boolean;
  branch_ids: string[];

  constructor(hide_trip_cost: IHideTripCost) {
    this.enabled = hide_trip_cost.enabled;
    this.branch_ids = hide_trip_cost.branch_ids;
  }
}

export class Settings {
  scan: boolean;
  cluster: Cluster;
  hub: Hub;
  pooling: Pooling;
  ratings: string[];
  abandonment_reasons: string[];
  partial_delivery_reasons: string[];
  task_pause_reasons: string[];
  sms_enabled: boolean;
  vehicle_types: string[];
  terminate_orders: TerminateOrders;
  qr_typing: boolean;
  qr_typing_rights: boolean;
  secondary_driver_rights: boolean;
  use_third_party_api: boolean;
  partners: [];
  geofence: Geofence;
  pooling_geofence: PoolingGeofence;
  recipient_name: RecipientName;
  route_optimisation: RouteOptimisation;
  trip_sequencing: TripSequencing;
  order_history: OrderHistory;
  can_create_trip: CanCreateTrip;
  can_retry_abandoned: CanRetryAbandoned;
  automatic_returns: AutomaticReturns;
  order_nearby: OrderNearby;
  required_returns: GenericSetting;
  hide_trip_cost: HideTripCost;

  constructor(
    scan: boolean,
    cluster: any,
    hub: any,
    pooling: any,
    ratings: string[],
    abandonment_reasons: string[],
    partial_delivery_reasons: string[],
    task_pause_reasons: string[],
    sms_enabled: boolean,
    vehicle_types: string[],
    terminate_orders: any,
    qr_typing: boolean,
    qr_typing_rights: boolean,
    secondary_driver_rights: boolean,
    use_third_party_api: boolean,
    partners: [],
    geofence: any,
    pooling_geofence: any,
    recipient_name: any,
    route_optimisation: any,
    trip_sequencing: any,
    order_history: any,
    can_create_trip: any,
    can_retry_abandoned: any,
    automatic_returns: any,
    order_nearby: any,
    required_returns: any,
    hide_trip_cost: any,
    scopes: any
  ) {
    this.scan = scan;
    this.ratings = ratings.map((rating: string) => rating);
    this.abandonment_reasons = abandonment_reasons.map(
      (abandonment_reason: string) => abandonment_reason
    );
    this.partial_delivery_reasons =
      partial_delivery_reasons?.map((partial_delivery_reason: string) => partial_delivery_reason) ||
      [];
    this.task_pause_reasons = task_pause_reasons?.map((reason) => reason) || [];
    this.cluster = new Cluster(cluster.enabled, cluster.distance, cluster.size, cluster.lock);
    this.hub = hub;
    this.pooling = new Pooling(pooling.enabled, pooling.algorithm);
    this.sms_enabled = sms_enabled;
    this.vehicle_types = vehicle_types.map((vehicle_type: string) => vehicle_type);
    this.terminate_orders = new TerminateOrders(
      terminate_orders.enabled || false,
      terminate_orders.max_age || 24
    );
    this.qr_typing = qr_typing;
    this.qr_typing_rights = qr_typing_rights;
    this.secondary_driver_rights = secondary_driver_rights;
    this.use_third_party_api = use_third_party_api;
    this.partners = partners;
    this.geofence = new Geofence(
      geofence?.enabled || false,
      geofence?.branch_distance || 0,
      geofence?.customer_distance || 0,
      geofence?.customer_arrived_distance || 0,
      geofence?.branch_distance_enabled || false,
      geofence?.customer_distance_enabled || false,
      geofence?.customer_arrived_enabled || false,
      geofence?.end_trip_enabled || false,
      geofence?.end_trip_distance || 0
    );
    this.pooling_geofence = new PoolingGeofence(
      pooling_geofence?.enabled || false,
      pooling_geofence?.priority_distance || 0,
      pooling_geofence?.maximum_distance || 0
    );
    this.recipient_name = new RecipientName(
      recipient_name?.enabled || false,
      recipient_name?.selected || []
    );
    this.route_optimisation = new RouteOptimisation(
      route_optimisation?.priority || 'Default',
      route_optimisation?.ro_start_end_locations || new ROStartEndLocations(route_optimisation)
    );
    this.trip_sequencing = new TripSequencing(trip_sequencing?.enabled || false);
    this.order_history = new OrderHistory(order_history);
    this.can_create_trip = new CanCreateTrip(can_create_trip?.enabled || false);
    this.can_retry_abandoned = new CanRetryAbandoned(can_retry_abandoned?.enabled || false);
    this.automatic_returns = new AutomaticReturns(automatic_returns);
    this.order_nearby = new OrderNearby(order_nearby?.enabled || false, order_nearby?.radius || 0);
    this.required_returns = new GenericSetting(required_returns);
    this.hide_trip_cost = new HideTripCost(hide_trip_cost ?? { enabled: false, branch_ids: [] });
  }
}
