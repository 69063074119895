export enum Scope {
  // Company Management
  // COMPANY_STRUCTURE_ADD = 'company:structure:add',
  // COMPANY_DETAILS_EDIT = 'company:details:edit',

  // Orders Management
  ORDERS_CREATE = 'orders:create',
  ORDERS_RECREATE = 'orders:recreate',
  ORDERS_ADD_TO_TRIP = 'orders:add-to-trip',
  ORDERS_CANCEL = 'orders:cancel',
  ORDERS_EDIT = 'orders:edit',
  ORDERS_RESET = 'orders:reset',
  ORDERS_EXPORT = 'orders:export',
  IMPORT_CREATE = 'import:create',

  // Trips Management
  TRIPS_CREATE = 'trips:create',
  TRIPS_CANCEL = 'trips:cancel',
  TRIPS_SEQUENCE = 'trips:sequence',
  TRIPS_REMOVE_DRIVER = 'trips:remove-driver',
  TRIPS_ASSIGN_DRIVER = 'trips:assign-driver',
  TRIPS_END = 'trips:end',
  TRIPS_PROGRESS_ORDER_STATES = 'trips:progress-order-states',

  // Reports Management
  PAYMENTS_REPORTS = "payments:reports",
}

export const SCOPE_LABELS: Record<Scope, string> = {
  // Company Management
  // [Scope.COMPANY_STRUCTURE_ADD]: 'Manage Company Structure',
  // [Scope.COMPANY_DETAILS_EDIT]: 'Edit Company Details',

  // Orders Management
  [Scope.ORDERS_CREATE]: 'Create Orders',
  [Scope.ORDERS_RECREATE]: 'Recreate Orders',
  [Scope.ORDERS_ADD_TO_TRIP]: 'Add Orders to Trip',
  [Scope.ORDERS_CANCEL]: 'Cancel Orders',
  [Scope.ORDERS_EDIT]: 'Edit Orders',
  [Scope.ORDERS_RESET]: 'Reset Orders',
  [Scope.ORDERS_EXPORT]: 'Export Orders',
  [Scope.IMPORT_CREATE]: 'Import Orders',

  // Trips Management
  [Scope.TRIPS_CREATE]: 'Create Trips',
  [Scope.TRIPS_CANCEL]: 'Cancel Trips',
  [Scope.TRIPS_SEQUENCE]: 'Manage Trip Sequence',
  [Scope.TRIPS_REMOVE_DRIVER]: 'Remove Driver from Trip',
  [Scope.TRIPS_ASSIGN_DRIVER]: 'Assign Driver to Trip',
  [Scope.TRIPS_END]: 'End Trip',
  [Scope.TRIPS_PROGRESS_ORDER_STATES]: 'Progress Order States in Trip',

  // Reports Management
  [Scope.PAYMENTS_REPORTS]: 'View Payment Reports',
};

export const paymentScopes = [
    Scope.PAYMENTS_REPORTS
]

export const scopeGroupLabels: Record<string, string> = {
  orders: 'Orders Management',
  trips: 'Trip Management',
  reports: 'Reports Management',
  company: 'Company Management',
  users: 'Users Management',
};

export const groupedScopes = {
  // "Company Management": [
  //   Scope.COMPANY_STRUCTURE_ADD,
  //   Scope.COMPANY_DETAILS_EDIT
  // ],
  [scopeGroupLabels.orders]: [
    Scope.ORDERS_CREATE,
    Scope.ORDERS_RECREATE,
    Scope.IMPORT_CREATE,
    Scope.ORDERS_CANCEL,
    Scope.ORDERS_EDIT,
    Scope.ORDERS_RESET,
    Scope.ORDERS_EXPORT, 
    Scope.ORDERS_ADD_TO_TRIP, 
  ],
  [scopeGroupLabels.trips]: [
    Scope.TRIPS_CANCEL,
    Scope.TRIPS_SEQUENCE,
    Scope.TRIPS_CREATE, 
    Scope.TRIPS_REMOVE_DRIVER,
    Scope.TRIPS_ASSIGN_DRIVER,
    Scope.TRIPS_END,
    Scope.TRIPS_PROGRESS_ORDER_STATES 
  ],
  [scopeGroupLabels.reports]: []
};