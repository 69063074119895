// @ts-check
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { getHubAction } from '../redux/actions/hub-actions';

import { makeStyles } from '@material-ui/core/styles';

import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';

import Grid from '@material-ui/core/Grid';
import { LoadingComponent } from '../components/loading-component';
import { HubInformationComponent } from '../components/hub-information-component';
import { HubTabsComponent } from '../components/hub-tabs-component';
import { HubSettingsComponent } from '../components/hub-settings-component.js';
import { HubMapComponent } from '../components/hub-map-component';
import { BackNavigationComponent } from '../../../../core/routes/components/back-navigation-component';
import { getSettingsAction } from '../../../settings/presentation/redux/actions/settings-actions';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0),
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
  container: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  sectionHeading: {
    margin: theme.spacing(3)
  },
  userHeader: {
    textAlign: 'center'
  },
  userLogo: {
    height: '10rem',
    width: '10rem',
    objectFit: 'contain'
  },
  large: {
    height: '8rem',
    width: '8rem',
    objectFit: 'contain'
  },
  margin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },

  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    cursor: 'pointer'
  }
}));

function CenteredGrid({ hub, crossClustering }) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <HubMapComponent hub={hub} />
      </Grid>

      <Grid item xs={12} md={8}>
        <HubInformationComponent hub={hub} />

        {
          crossClustering && (
            <HubSettingsComponent hub={hub} />
          )
        }
        <HubTabsComponent hub={hub} />
      </Grid>
    </Grid>
  );
}

export default function HubPage() {
  // @ts-ignore
  const { id } = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();

  const { hub, loading: hubLoading, success } = useSelector((state) => state.hub) || { hub: null, loading: true, success: false };
  const { settings, loading: settingsLoading } = useSelector((state) => state.settings) || { settings: {} };

  const crossClustering = settings?.hub?.cross_clustering?.enabled || false;

  useEffect(() => {
    dispatch(getSettingsAction());
    dispatch(getHubAction(id));
  }, [dispatch, id]);

  // Wait until both hub and settings are fully loaded
  if (hubLoading || settingsLoading) {
    return <LoadingComponent />;
  }

  // Handle case where hub or settings data is missing
  if (!hub || !settings) {
    return (
      <Container className={classes.container}>
        <p>Error: Required data could not be loaded.</p>
      </Container>
    );
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <Container className={classes.container}>
        {success ? (
          <>
            <BackNavigationComponent
              page="Hub"
              uri="/hubs"
              path={[
                { page: 'Home', uri: '/' },
                { page: 'Hubs', uri: '/hubs' },
              ]}
            />

            <CenteredGrid hub={hub} crossClustering={crossClustering} />
          </>
        ) : (
          <p>Error: Unable to load hub data.</p>
        )}
      </Container>
    </React.Fragment>
  );
}
