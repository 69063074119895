import { IHideTripCost, Settings } from '../../domain/entities/settings-entity';
import { SettingsRepository } from '../../domain/repositories/settings-repository';
import {
  AutomaticReturns,
  BranchLogo,
  OrderHistory,
  OrderNearby,
  ROStartEndLocations,
  TerminateOrders
} from '../../presentation/redux/types/settings-types';
import * as datasources from '../datasources';

export class SettingsRepositoryImpl implements SettingsRepository {
  async GetSettings(): Promise<Settings> {
    const result = await datasources.getSettings();
    return this.mapToSettings(result);
  }

  /**
   * Generic helper method to update settings and fetch latest values.
   */
  private async updateSettings<T>(updateFn: (data: T) => Promise<any>, data: T): Promise<Settings> {
    console.log('updateSettings');
    console.log(data);
    const result = await updateFn(data);
    return this.mapToSettings(result);
  }

  /**
   * Maps API response to the Settings entity.
   */
  private mapToSettings(result: any): Settings {
    return new Settings(
      result.scan,
      result.cluster,
      result.hub,
      result.pooling,
      result.ratings,
      result.abandonment_reasons,
      result.partial_delivery_reasons,
      result.task_pause_reasons,
      result.sms_enabled,
      result.vehicle_types,
      result.terminate_orders,
      result.qr_typing,
      result.qr_typing_rights,
      result.secondary_driver_rights,
      result.use_third_party_api,
      result.partners,
      result.geofence,
      result.pooling_geofence,
      result.recipient_name,
      result.route_optimisation,
      result.trip_sequencing,
      result.order_history,
      result.can_create_trip,
      result.can_retry_abandoned,
      result.automatic_returns,
      result.order_nearby,
      result.required_returns,
      result.hide_trip_cost,
      result.scopes
    );
  }

  // Using the helper function to reduce redundancy
  async EditRouteOptimisation(route_optimisation: object): Promise<Settings> {
    return this.updateSettings(datasources.editRouteOptimisation, route_optimisation);
  }

  async EditRatings(ratings: string[]): Promise<Settings> {
    return this.updateSettings(datasources.editRatings, ratings);
  }

  async EditAbandonmentReasons(reasons: string[]): Promise<Settings> {
    return this.updateSettings(datasources.editAbandonmentReasons, reasons);
  }

  async EditPartialDeliveryReasons(reasons: string[]): Promise<Settings> {
    return this.updateSettings(datasources.editPartialDeliveryReasons, reasons);
  }

  async EditTaskPauseReasons(reasons: string[]): Promise<Settings> {
    return this.updateSettings(datasources.editTaskPauseReasons, reasons);
  }

  async EditPooling(algorithm: string): Promise<Settings> {
    return this.updateSettings(datasources.editPooling, algorithm);
  }

  async EditCluster(
    distance: number,
    size: number,
    lock_strategy: string,
    lock_time: number,
    collection_time_range: number
  ): Promise<Settings> {
    return this.mapToSettings(
      await datasources.editCluster(distance, size, lock_strategy, lock_time, collection_time_range)
    );
  }

  async EditSms(sms_enabled: boolean): Promise<Settings> {
    return this.updateSettings(datasources.editSms, sms_enabled);
  }

  async EditVehicleTypes(types: string[]): Promise<Settings> {
    return this.updateSettings(datasources.editVehicleTypes, types);
  }

  async EditTerminateOrders(terminate_orders: TerminateOrders): Promise<Settings> {
    return this.updateSettings(datasources.editTerminateOrders, terminate_orders);
  }

  async EditQRTyping(qr_typing: boolean): Promise<Settings> {
    return this.updateSettings(datasources.editQRTyping, qr_typing);
  }

  async EditGeofence(geofence: object): Promise<Settings> {
    return this.updateSettings(datasources.editGeofence, geofence);
  }

  async EditPoolingGeofence(pooling_geofence: object): Promise<Settings> {
    return this.updateSettings(datasources.editPoolingGeofence, pooling_geofence);
  }

  async EditRecipientName(recipient_name: object): Promise<Settings> {
    return this.updateSettings(datasources.editRecipientName, recipient_name);
  }

  async EditTripSequencing(trip_sequencing: object): Promise<Settings> {
    return this.updateSettings(datasources.editTripSequencing, trip_sequencing);
  }

  async EditOrderHistory(order_history: OrderHistory): Promise<Settings> {
    return this.updateSettings(datasources.editOrderHistory, order_history);
  }

  async EditTripAppCreate(can_create_trip: object): Promise<Settings> {
    return this.updateSettings(datasources.editTripCreateFromApp, can_create_trip);
  }

  async EditCanRetryAbandoned(can_retry_abandoned: object): Promise<Settings> {
    return this.updateSettings(datasources.editCanRetryAbandoned, can_retry_abandoned);
  }

  async EditBranchLogo(branch_logo: BranchLogo): Promise<Settings> {
    return this.updateSettings(datasources.editBranchLogo, branch_logo);
  }

  async EditAutomaticReturns(automatic_returns: AutomaticReturns): Promise<Settings> {
    return this.updateSettings(datasources.editAutomaticReturns, automatic_returns);
  }

  async EditROStartEndLocations(ro_start_end_locations: ROStartEndLocations): Promise<Settings> {
    return this.updateSettings(datasources.editROStartEndLocations, ro_start_end_locations);
  }

  async EditOrderNearby(order_nearby: OrderNearby): Promise<Settings> {
    return this.updateSettings(datasources.editOrderNearby, order_nearby);
  }

  async EditHideTripCost(edit_hide_trip_cost: IHideTripCost): Promise<Settings> {
    return this.updateSettings(datasources.editHideTripCost, edit_hide_trip_cost);
  }

  async EditScopes(scopes: string[]): Promise<Settings> {
    return this.updateSettings(datasources.editScopes, scopes);
  }
}