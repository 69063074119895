import { Dispatch } from 'redux';
import {
  LOADING_FAILURE,
  LOADING_REQUEST,
  LOADING_SUCCESS
} from '../../../../../core/redux/types/loading-types';
import { HubRepositoryImpl } from '../../../data/repositories/hub-repository-impl';
import { HubUsecaseImpl } from '../../../domain/usecases/hub-usecase';
import { HUB_LOAD_FAILURE, HUB_LOAD_REQUEST, HUB_LOAD_SUCCESS } from '../types/hub-types';
import { HUBS_LOAD_FAILURE, HUBS_LOAD_REQUEST, HUBS_LOAD_SUCCESS } from '../types/hubs-types';

export const getHubsAction = () => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: HUBS_LOAD_REQUEST });

    try {
      const hubRepository = new HubRepositoryImpl();
      const hubUsecase = new HubUsecaseImpl(hubRepository);

      const result = await hubUsecase.GetHubs();

      dispatch({ type: HUBS_LOAD_SUCCESS, payload: result });
    } catch (error) {
      console.log(error);
      dispatch({ type: HUBS_LOAD_FAILURE, error });
    }
  };
};

export const getHubAction = (hub_id: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: HUB_LOAD_REQUEST });

    try {
      const hubRepository = new HubRepositoryImpl();
      const hubUsecase = new HubUsecaseImpl(hubRepository);

      const result = await hubUsecase.GetHub(hub_id);

      dispatch({ type: HUB_LOAD_SUCCESS, payload: result });
    } catch (error) {
      dispatch({ type: HUB_LOAD_FAILURE, error });
    }
  };
};

export const createHubAction = (name: string, props: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: HUB_LOAD_REQUEST });

    try {
      const hubRepository = new HubRepositoryImpl();
      const hubUsecase = new HubUsecaseImpl(hubRepository);
      const result = await hubUsecase.CreateHub(name);
      dispatch({ type: HUB_LOAD_SUCCESS, payload: result });
      dispatch({ type: LOADING_SUCCESS });
      props.history.push(`/hubs`);
    } catch (error) {
      dispatch({ type: HUB_LOAD_FAILURE, error });
    }
  };
};

export const editHubAction = (hub_id: string, name: string, props: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: LOADING_REQUEST });

    try {
      const hubRepository = new HubRepositoryImpl();
      const hubUsecase = new HubUsecaseImpl(hubRepository);

      const result = await hubUsecase.EditHub(hub_id, name);

      dispatch({ type: HUB_LOAD_SUCCESS, payload: result });
      dispatch({ type: LOADING_SUCCESS });
      props.handleClose();
    } catch (error) {
      dispatch({ type: LOADING_FAILURE, error });
    }
  };
};

export const removeHubFromRegionAction = (region_id: string, hub_id: string, props: any) => {
  return async (dispatch: Dispatch) => {
    try {
      const hubRepository = new HubRepositoryImpl();
      const hubUsecase = new HubUsecaseImpl(hubRepository);

      const result = await hubUsecase.RemoveHubFromRegion(region_id, hub_id);

      dispatch({ type: HUB_LOAD_SUCCESS, payload: result });

      props.closeBackdrop();
      props.showSnackbar();
    } catch (error) {
      props.closeBackdrop();
      props.showSnackbar('error', (error as any).message);
    }
  };
};

export const grantHubCrossClusteringAction = (hub_id: string, props: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: LOADING_REQUEST });
    try {
      const hubRepository = new HubRepositoryImpl();
      const hubUsecase = new HubUsecaseImpl(hubRepository);

      const result = await hubUsecase.GrantHubCrossClustering(hub_id);

      dispatch({ type: HUB_LOAD_SUCCESS, payload: result });
      dispatch({ type: LOADING_SUCCESS });

      props.closeBackdrop();
      props.showSnackbar();
    } catch (error) {
      dispatch({ type: LOADING_FAILURE, error });
      props.closeBackdrop();
      props.showSnackbar('error', (error as any).message);
    }
  };
};

export const revokeHubCrossClusteringAction = (hub_id: string, props: any) => {
  return async (dispatch: Dispatch) => {
    try {
      const hubRepository = new HubRepositoryImpl();
      const hubUsecase = new HubUsecaseImpl(hubRepository);

      const result = await hubUsecase.RevokeHubCrossClustering(hub_id);

      dispatch({ type: HUB_LOAD_SUCCESS, payload: result });

      props.closeBackdrop();
      props.showSnackbar();
    } catch (error) {
      props.closeBackdrop();
      props.showSnackbar('error', (error as any).message);
    }
  };
};
