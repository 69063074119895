import { Dispatch } from 'redux';

import { UserRepositoryImpl } from '../../../data/repositories/user-repository-impl';
import { UserUsecaseImpl } from '../../../domain/usecases/user-usecase';
import { USER_LOAD_REQUEST, USER_LOAD_SUCCESS, USER_LOAD_FAILURE } from '../types/user-types';
import { USERS_LOAD_FAILURE, USERS_LOAD_REQUEST, USERS_LOAD_SUCCESS } from '../types/users-types';
import {
  LOADING_FAILURE,
  LOADING_REQUEST,
  LOADING_SUCCESS
} from '../../../../../core/redux/types/loading-types';
import { UPLOAD_FAILURE, UPLOAD_REQUEST, UPLOAD_SUCCESS } from '../types/upload-types';

export const createUserAction = (
  firstname: string,
  lastname: string,
  email: string,
  mobile_no: string,
  password: string,
  scopes: string[],
  props: any
) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: USER_LOAD_REQUEST });

    try {
      const userRepository = new UserRepositoryImpl();
      const userUsecase = new UserUsecaseImpl(userRepository);

      const result = await userUsecase.CreateUser(firstname, lastname, email, mobile_no, password, scopes);

      dispatch({ type: USER_LOAD_SUCCESS, payload: result });
      props.history.push(`/users/${result.id}/view`);
    } catch (error) {
      dispatch({ type: USER_LOAD_FAILURE, error });
    }
  };
};

export const editUserAction = (
  user_id: string,
  firstname: string,
  lastname: string,
  props: any
) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: LOADING_REQUEST });

    try {
      const userRepository = new UserRepositoryImpl();
      const userUsecase = new UserUsecaseImpl(userRepository);

      const result = await userUsecase.EditUser(user_id, firstname, lastname);

      dispatch({ type: USER_LOAD_SUCCESS, payload: result });
      dispatch({ type: LOADING_SUCCESS });
      props.handleClose();
    } catch (error) {
      dispatch({ type: LOADING_FAILURE, error });
    }
  };
};

export const editUserEmailAction = (user_id: string, email: string, props: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: LOADING_REQUEST });

    try {
      const userRepository = new UserRepositoryImpl();
      const userUsecase = new UserUsecaseImpl(userRepository);

      const result = await userUsecase.EditUserEmail(user_id, email);

      dispatch({ type: USER_LOAD_SUCCESS, payload: result });
      dispatch({ type: LOADING_SUCCESS });
      props.handleClose();
    } catch (error) {
      dispatch({ type: LOADING_FAILURE, error });
    }
  };
};

export const editUserPhoneAction = (user_id: string, mobile_no: string, props: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: LOADING_REQUEST });

    try {
      const userRepository = new UserRepositoryImpl();
      const userUsecase = new UserUsecaseImpl(userRepository);

      const result = await userUsecase.EditUserPhone(user_id, mobile_no);

      dispatch({ type: USER_LOAD_SUCCESS, payload: result });
      dispatch({ type: LOADING_SUCCESS });
      props.handleClose();
    } catch (error) {
      dispatch({ type: LOADING_FAILURE, error });
    }
  };
};

export const editUserPasswordAction = (user_id: string, password: string, props: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: LOADING_REQUEST });

    try {
      const userRepository = new UserRepositoryImpl();
      const userUsecase = new UserUsecaseImpl(userRepository);

      const result = await userUsecase.EditUserPassword(user_id, password);

      dispatch({ type: USER_LOAD_SUCCESS, payload: result });
      dispatch({ type: LOADING_SUCCESS });
      props.handleClose();
    } catch (error) {
      dispatch({ type: LOADING_FAILURE, error });
    }
  };
};

export const editUserScopesAction = (user_id: string, scopes: string[], { closeBackdrop, showSnackbar }: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: LOADING_REQUEST });

    try {
      const userRepository = new UserRepositoryImpl();
      const userUsecase = new UserUsecaseImpl(userRepository);

      const result = await userUsecase.EditUserScopes(user_id, scopes);

      dispatch({ type: USER_LOAD_SUCCESS, payload: result });
      dispatch({ type: LOADING_SUCCESS });
      closeBackdrop();
      showSnackbar();
    } catch (error) {
      dispatch({ type: LOADING_FAILURE, error });
      closeBackdrop();
      showSnackbar('error', error);
    }
  };
};

export const getUserAction = (user_id: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: USER_LOAD_REQUEST });

    try {
      const userRepository = new UserRepositoryImpl();
      const userUsecase = new UserUsecaseImpl(userRepository);

      const result = await userUsecase.GetUser(user_id);

      dispatch({ type: USER_LOAD_SUCCESS, payload: result });
    } catch (error) {
      dispatch({ type: USER_LOAD_FAILURE, error });
    }
  };
};

export const getUsersAction = () => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: USERS_LOAD_REQUEST });

    try {
      const userRepository = new UserRepositoryImpl();
      const userUsecase = new UserUsecaseImpl(userRepository);

      const result = await userUsecase.GetUsers();

      dispatch({ type: USERS_LOAD_SUCCESS, payload: result });
    } catch (error) {
      dispatch({ type: USERS_LOAD_FAILURE, error });
    }
  };
};

export const uploadUserImageAction = (
  client_id: string,
  user_id: string,
  file: any,
  props: any
) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: UPLOAD_REQUEST });

    try {
      const userRepository = new UserRepositoryImpl();
      const userUsecase = new UserUsecaseImpl(userRepository);

      const result = await userUsecase.UploadUserImage(client_id, user_id, file);
      // const result = await userUsecase.GetUser(user_id);

      dispatch({ type: USER_LOAD_SUCCESS, payload: result });
      dispatch({ type: UPLOAD_SUCCESS });
      props.uploadSuccess();
    } catch (error) {
      dispatch({ type: UPLOAD_FAILURE, error });
    }
  };
};

export const grantHubAccessAction = (user_id: string, hub_id: string, props: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: LOADING_REQUEST });

    try {
      const userRepository = new UserRepositoryImpl();
      const userUsecase = new UserUsecaseImpl(userRepository);

      const result = await userUsecase.GrantHubAccess(user_id, hub_id);

      dispatch({ type: USER_LOAD_SUCCESS, payload: result });
      dispatch({ type: LOADING_SUCCESS });
      props.showSnackbar();
    } catch (error) {
      dispatch({ type: LOADING_FAILURE, error });
    }
  };
};

export const revokeHubAccessAction = (user_id: string, hub_id: string, props: any) => {
  return async (dispatch: Dispatch) => {
    try {
      const userRepository = new UserRepositoryImpl();
      const userUsecase = new UserUsecaseImpl(userRepository);

      const result = await userUsecase.RevokeHubAccess(user_id, hub_id);

      dispatch({ type: USER_LOAD_SUCCESS, payload: result });

      props.closeBackdrop();
      props.showSnackbar();
    } catch (error) {
      props.closeBackdrop();
      props.showSnackbar('error', error);
    }
  };
};

export const grantRegionAccessAction = (user_id: string, region_id: string, props: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: LOADING_REQUEST });

    try {
      const userRepository = new UserRepositoryImpl();
      const userUsecase = new UserUsecaseImpl(userRepository);

      const result = await userUsecase.GrantRegionAccess(user_id, region_id);

      dispatch({ type: USER_LOAD_SUCCESS, payload: result });
      dispatch({ type: LOADING_SUCCESS });
      props.showSnackbar();
    } catch (error) {
      dispatch({ type: LOADING_FAILURE, error });
    }
  };
};

export const revokeRegionAccessAction = (user_id: string, region_id: string, props: any) => {
  return async (dispatch: Dispatch) => {
    try {
      const userRepository = new UserRepositoryImpl();
      const userUsecase = new UserUsecaseImpl(userRepository);

      const result = await userUsecase.RevokeRegionAccess(user_id, region_id);

      dispatch({ type: USER_LOAD_SUCCESS, payload: result });

      props.closeBackdrop();
      props.showSnackbar();
    } catch (error) {
      props.closeBackdrop();
      props.showSnackbar('error', error);
    }
  };
};

export const grantAdminRightsAction = (user_id: string, props: any) => {
  return async (dispatch: Dispatch) => {
    try {
      const userRepository = new UserRepositoryImpl();
      const userUsecase = new UserUsecaseImpl(userRepository);

      const result = await userUsecase.GrantAdminRights(user_id);

      dispatch({ type: USER_LOAD_SUCCESS, payload: result });

      props.closeBackdrop();
      props.showSnackbar('success', 'Admin access granted');
    } catch (error) {
      props.closeBackdrop();
      props.showSnackbar('error', error);
    }
  };
};

export const revokeAdminRightsAction = (user_id: string, props: any) => {
  return async (dispatch: Dispatch) => {
    try {
      const userRepository = new UserRepositoryImpl();
      const userUsecase = new UserUsecaseImpl(userRepository);

      const result = await userUsecase.RevokeAdminRights(user_id);

      dispatch({ type: USER_LOAD_SUCCESS, payload: result });

      props.closeBackdrop();
      props.showSnackbar('success', 'Admin access revoked');
    } catch (error) {
      props.closeBackdrop();
      props.showSnackbar('error', error);
    }
  };
};

export const grantFleetAccessAction = (user_id: string, props: any) => {
  return async (dispatch: Dispatch) => {
    try {
      const userRepository = new UserRepositoryImpl();
      const userUsecase = new UserUsecaseImpl(userRepository);

      const result = await userUsecase.GrantFleetAccess(user_id);

      dispatch({ type: USER_LOAD_SUCCESS, payload: result });

      props.closeBackdrop();
      props.showSnackbar('success', 'Fleet access granted');
    } catch (error) {
      props.closeBackdrop();
      props.showSnackbar('error', error);
    }
  };
};

export const revokeFleetAccessAction = (user_id: string, props: any) => {
  return async (dispatch: Dispatch) => {
    try {
      const userRepository = new UserRepositoryImpl();
      const userUsecase = new UserUsecaseImpl(userRepository);

      const result = await userUsecase.RevokeFleetAccess(user_id);

      dispatch({ type: USER_LOAD_SUCCESS, payload: result });

      props.closeBackdrop();
      props.showSnackbar('success', 'Fleet access revoked');
    } catch (error) {
      dispatch({ type: LOADING_FAILURE, error });
      props.closeBackdrop();
      props.showSnackbar('error', error);
    }
  };
};