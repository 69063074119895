import React, { useState } from 'react';
import { isValidNumber, validatePhoneNumberLength } from 'libphonenumber-js';
import { useHistory } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { createUserAction } from '../redux/actions/user-actions';

import { Formik } from 'formik';
import * as Yup from 'yup';

import { makeStyles } from '@material-ui/core/styles';
import { TextField, Button, Grid, CircularProgress, Checkbox, FormControl, FormGroup, FormControlLabel, FormLabel } from '@material-ui/core';

import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import InputAdornment from '@material-ui/core/InputAdornment/InputAdornment';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton/IconButton';
import { BackNavigationComponent } from '../../../../core/routes/components/back-navigation-component';

import { Scope, SCOPE_LABELS } from '../../../../core/types/scopes';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    }
  },
  container: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  sectionHeading: {
    margin: theme.spacing(2)
  },
  margin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  formButtons: {
    marginTop: theme.spacing(4)
  },
  errorText: {
    color: '#f44336',
    textAlign: 'center',
    display: 'block',
    margin: theme.spacing(3)
  }
}));

function ScopeGroup({ group, scopes, values, handleScopeChange }) {
  if (!scopes || scopes.length === 0) return null;
  return (
    <Grid item xs={12} md={4} style={{ paddingRight: 15 }}>
      <FormLabel component="legend" style={{ fontSize: 16, fontWeight: 'bold', marginBottom: 10 }}>
        {group}
      </FormLabel>
      <FormGroup>
        {scopes.map((scope) => (
          <FormControlLabel
            key={scope}
            control={
              <Checkbox
                checked={values.scopes.includes(scope)}
                onChange={handleScopeChange}
                value={scope}
              />
            }
            label={SCOPE_LABELS[scope] || (scope ? scope.replace(/_/g, ' ') : 'Unknown Scope')}
          />
        ))}
      </FormGroup>
    </Grid>
  );
}

function CreateUserForm(props) {
  const classes = useStyles();
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);

  const { error, loading } = useSelector((state) => state.user);
   const { me } = useSelector((state) => state.me);

  const { values, errors, touched, handleSubmit, handleChange, handleBlur } = props;

  /**
   * @param {string} name
   */
  const formikProps = (name, initialValue = '') => ({
    name: name,
    value: typeof values[name] !== 'undefined' ? values[name] : initialValue,
    onChange: handleChange,
    onBlur: handleBlur,
    error: touched[name] && Boolean(errors[name]),
    helperText: touched[name] ? errors[name] : ''
  });

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleScopeChange = (event) => {
    const scope = event.target.value;
    const updatedScopes = values.scopes.includes(scope)
      ? values.scopes.filter((s) => s !== scope)
      : [...values.scopes, scope];
  
    handleChange({
      target: { name: 'scopes', value: updatedScopes }
    });
  };
  
  return (
    <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit}>
      <TextField
        {...formikProps('firstname')}
        id="outlined-full-width"
        label="First Name"
        type="text"
        variant="outlined"
        fullWidth={true}
      />

      <TextField
        {...formikProps('lastname')}
        id="outlined-full-width"
        label="Last Name"
        type="text"
        variant="outlined"
        fullWidth={true}
      />

      <TextField
        {...formikProps('email')}
        id="outlined-full-width"
        label="Email"
        type="text"
        variant="outlined"
        fullWidth={true}
      />

      <TextField
        {...formikProps('mobile_no')}
        id="outlined-full-width"
        label="Phone no."
        type="text"
        variant="outlined"
        fullWidth={true}
      />

      <TextField
        {...formikProps('password')}
        id="outlined-full-width"
        label="Password"
        type={showPassword ? 'text' : 'password'}
        autoComplete="new-password"
        variant="outlined"
        fullWidth={true}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          )
        }}
      />

<FormControl component="fieldset" className={classes.root} fullWidth>
  <FormLabel component="legend" style={{ fontWeight: 'bold', marginBottom: 10 }}>
    Permissions
  </FormLabel>

  <Grid container spacing={2} style={{ marginBottom: 15 }}>
    <Grid item>
      <Button
        variant="outlined"
        size="small"
        color="primary"
        onClick={() => handleChange({ target: { name: 'scopes', value: Object.values(Scope) } })}
      >
        Select All
      </Button>
    </Grid>
    <Grid item>
      <Button
        variant="outlined"
        size="small"
        color="secondary"
        onClick={() => handleChange({ target: { name: 'scopes', value: [] } })}
      >
        Deselect All
      </Button>
    </Grid>
  </Grid>

  <Grid container spacing={3} style={{ width: '100%' }}>
      <ScopeGroup
        group="Order Management"
        scopes={[
          Scope.ORDERS_CREATE,
          Scope.ORDERS_RECREATE,
          Scope.IMPORT_CREATE,
          Scope.ORDERS_CANCEL,
          Scope.ORDERS_EDIT,
          Scope.ORDERS_RESET,
          Scope.ORDERS_EXPORT
        ]}
        values={values}
        handleScopeChange={handleScopeChange}
      />

      <ScopeGroup
        group="Trip Management"
        scopes={[
          Scope.TRIPS_CREATE,
          Scope.TRIPS_CANCEL,
          Scope.TRIPS_SEQUENCE,
          Scope.TRIPS_REMOVE_DRIVER,
          Scope.TRIPS_ASSIGN_DRIVER,
          Scope.TRIPS_END,
          Scope.TRIPS_PROGRESS_ORDER_STATES
        ]}
        values={values}
        handleScopeChange={handleScopeChange}
      />

      <ScopeGroup
        group="Reports Management"
        scopes={ me?.organization?.allow_custom_payment_fields ? [
          Scope.PAYMENTS_REPORTS,
        ] : []}
        values={values}
        handleScopeChange={handleScopeChange}
      />  

      </Grid>
      </FormControl>

      <div className={classes.formButtons}>
        {!loading && (
          <>
            <Button
              className={classes.margin}
              type="submit"
              variant="contained"
              size="large"
              color="primary"
              fullWidth
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} color="inherit" /> : 'Create User'}
            </Button>
            <Button
              className={classes.margin}
              variant="outlined"
              size="large"
              color="secondary"
              fullWidth={true}
              onClick={() => history.push('/users')}
            >
              Cancel
            </Button>
          </>
        )}

        {loading && (
          <Button
            className={classes.margin}
            variant="contained"
            size="large"
            color="primary"
            fullWidth={true}
          >
            <CircularProgress color="inherit" size={26} />
          </Button>
        )}

        {error && (
          <div>
            <span className={classes.errorText}>{error.message}</span>
          </div>
        )}
      </div>
    </form>
  );
}

const validationSchema = Yup.object({
  firstname: Yup.string()
    .trim()
    .required('First name is required')
    .matches(/^[a-zA-Z\s]+$/, 'First name can only contain letters and spaces'),

  lastname: Yup.string()
    .trim()
    .required('Last name is required')
    .matches(/^[a-zA-Z\s]+$/, 'Last name can only contain letters and spaces'),

  email: Yup.string()
    .trim()
    .email('Enter a valid email address')
    .required('Email is required'),

  mobile_no: Yup.string()
    .trim()
    .required('Phone number is required')
    .test(
      'is-valid-phone',
      'Enter a valid phone number with country code (e.g., +9723656759)',
      (value) => {
        if (!value) return false;
        try {
          return isValidNumber(value) && validatePhoneNumberLength(value) === undefined;
        } catch (err) {
          return false;
        }
      }
    ),

  password: Yup.string()
    .trim()
    .required('Password is required')
    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
      'Password must have at least 8 characters, one uppercase letter, one lowercase letter, one number, and one special character'
    ),

  scopes: Yup.array()
    .of(Yup.string()) 
    .default([]) 
});

function CreateUserFormValidation() {
  const history = useHistory();
  const dispatch = useDispatch();

  const initialValues = {
    firstname: '',
    lastname: '',
    email: '',
    mobile_no: '',
    password: '',
    scopes: [] 
  };

  const submit = async (data, { setSubmitting, resetForm, setErrors }) => {
    try {
      setSubmitting(true);
      const props = { history };

      await dispatch(
        createUserAction(
          data.firstname.trim(),
          data.lastname.trim(),
          data.email.trim(),
          data.mobile_no.trim(),
          data.password,
          data.scopes,
          props
        )
      );


      resetForm();
      history.push('/users');
    } catch (error) {
      setErrors({ submit: error.message || 'An error occurred while creating the user' });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={submit}>
      {(props) => <CreateUserForm {...props} />}
    </Formik>
  );
}

export default function CreateUserPage() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <CssBaseline />
      <Container className={classes.container}>
      <BackNavigationComponent
              page="Create user"
              uri="/users"
              path={[
                {
                  page: 'Home',
                  uri: '/'
                },
                {
                  page: 'Users',
                  uri: '/users'
                }
              ]}
            />
        <Grid container spacing={2} justify="center">
          <Grid item xs={12} md={8}>
            <CreateUserFormValidation />
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
}
