import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  Button,
  Grid,
  Typography,
  Card,
  CardContent,
  IconButton,
  Backdrop,
  CircularProgress,
} from '@material-ui/core';

import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

import { SnackbarComponent } from '../../../../core/utils/components/snackbar-component';
import { SCOPE_LABELS, groupedScopes, paymentScopes, scopeGroupLabels } from '../../../../core/types/scopes';

import { makeStyles } from '@material-ui/core/styles';
import { editUserScopesAction } from '../redux/actions/user-actions';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(2),
  },
  card: {
    width: '100%', 
    maxWidth: '100%',
    margin: 'auto',
    padding: theme.spacing(2),
    marginTop: theme.spacing(4), 
  },
  sectionHeading: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
  },
  iconButton: {
    float: 'right',
  },
  saveCancelButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
  },
  column: {
    flex: 1,
    paddingRight: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  fullWidthButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },
}));

export function ScopesPermissionsComponent({ user }) {
  const classes = useStyles();
  const dispatch = useDispatch();
   const { me } = useSelector((state) => state.me);

  const [isEditing, setIsEditing] = useState(false);
  const [scopes, setScopes] = useState([]); 
  const [backdrop, setBackdrop] = useState(false);
  const [snackbar, setSnackbar] = useState(false);
  const [groups, setGroups] = useState({...groupedScopes});
  const [message, setMessage] = useState({ status: 'success', description: 'Permissions updated' });

  useEffect(() => {
    if (user?.permissions?.scopes && Array.isArray(user.permissions.scopes)) {
      let scopes = [...user.permissions.scopes];
      setScopes([...scopes]);
    } 
  }, [user]);

  useEffect(() => {
    if (me) {
      const { allow_custom_payment_fields }  = me.organization;

      if(allow_custom_payment_fields) {
        setGroups((state) => ({
          ...state,
          [scopeGroupLabels.reports]: [...new Set([
            ...state[scopeGroupLabels.reports],
            ...paymentScopes,
          ])],
        }));
      }
    }
  }, [me]);

  const showSnackbar = (status = 'success', description = 'Permissions updated') => {
    setSnackbar(true);
    setMessage({ status, description });
    setBackdrop(false);
  };

  const closeSnackbar = () => setSnackbar(false);
  const closeBackdrop = () => setBackdrop(false);

  const handleScopeChange = (event) => {
    const scope = event.target.value;
    setScopes((prevScopes) =>
      prevScopes.includes(scope)
        ? prevScopes.filter((s) => s !== scope) // Remove scope
        : [...prevScopes, scope] // Add scope
    );
  };

  const handleSave = () => {
    setBackdrop(true);
    dispatch(editUserScopesAction(user.id, scopes, { closeBackdrop, showSnackbar }));
    setIsEditing(false);
  };

  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography variant="h6">
          User Scopes
          {!isEditing ? (
            <IconButton className={classes.iconButton} onClick={() => setIsEditing(true)}>
              <EditIcon />
            </IconButton>
          ) : null}
        </Typography>

        <Grid container spacing={3}>
          {Object.entries(groups).map(([category, scopeList]) => {
            if (scopeList.length === 0) return null; // Skip empty categories
            return (
              <Grid item xs={12} md={4} key={category} className={classes.column}>
              <Typography variant="subtitle1" className={classes.sectionHeading}>
                {category}
              </Typography>
              <List>
                {scopeList.map((scope) => (
                  <ListItem key={scope} dense>
                    <ListItemIcon>
                      <Checkbox
                        checked={scopes.includes(scope)}
                        onChange={handleScopeChange}
                        value={scope}
                        disabled={!isEditing}
                      />
                    </ListItemIcon>
                    <ListItemText primary={SCOPE_LABELS[scope] || scope.replace(/_/g, ' ')} />
                  </ListItem>
                ))}
              </List>
            </Grid>
            )
          })}
        </Grid>

        {isEditing && (
          <div className={classes.saveCancelButtons}>
            <Button variant="contained" color="secondary" startIcon={<CloseIcon />} onClick={() => setIsEditing(false)}>
              Cancel
            </Button>
            <Button variant="contained" color="primary" startIcon={<CheckIcon />} onClick={handleSave} style={{ marginLeft: 10 }}>
              Save Changes
            </Button>
          </div>
        )}
      </CardContent>

      <Backdrop className={classes.backdrop} open={backdrop} onClick={closeBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <SnackbarComponent open={snackbar} onClose={closeSnackbar} severity={message.status} message={message.description} />
    </Card>
  );
}
