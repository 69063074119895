// @ts-nocheck
import React, { useEffect, useState } from 'react';

import { Formik } from 'formik';
import * as Yup from 'yup';

import { makeStyles } from '@material-ui/core/styles';
import {
  TextField,
  Button,
  MenuItem,
  Checkbox,
  ListItemText,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  FormLabel,
  FormControlLabel,
  Switch
} from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import { getHubs } from '../../../../hubs/data/datasources/get-hubs';
import { editTakealotSettings } from '../../../data/datasources/edit-takealot-settings';
import { useDispatch } from 'react-redux';
import { SETTINGS_LOAD_SUCCESS } from '../../redux/types/settings-types';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    }
  },
  sectionHeading: {
    margin: theme.spacing(2)
  },
  margin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  formButtons: {
    marginTop: theme.spacing(1),
    paddingBottom: theme.spacing(2)
  },
  errorText: {
    color: '#f44336',
    textAlign: 'center',
    display: 'block',
    margin: theme.spacing(3)
  }
}));

function EditTakealotSettingsForm(props) {
  const classes = useStyles();

  const { values, errors, touched, handleSubmit, handleChange, handleBlur, isSubmitting } = props;
  const [isLoading, setLoading] = useState(true);
  const [hubs, setHubs] = useState([]);

  const fetchHubs = async () => {
    const hubs = await getHubs();
    // @ts-ignore
    setHubs(hubs);
    setLoading(false);
  };

  // Fetch available hubs when the component loads.
  useEffect(() => {
    fetchHubs();
  }, []);

  /**
   * @param {string} name
   */
  const formikProps = (name, initialValue = '') => ({
    name: name,
    value: typeof values[name] !== 'undefined' ? values[name] : initialValue,
    onChange: handleChange,
    onBlur: handleBlur,
    error: touched[name] && Boolean(errors[name]),
    helperText: touched[name] ? errors[name] : ''
  });

  return (
    <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit}>
      <FormControl component="fieldset">
        <FormLabel component="legend">
          Require driver to return abandoned orders back to the branch.
        </FormLabel>
        <FormControlLabel
          control={
            <Switch
              {...formikProps('required_returns')}
              checked={formikProps('required_returns').value}
            />
          }
          label={formikProps('required_returns').value ? 'Enabled' : 'Disabled'}
        />
      </FormControl>
      <br />
      <FormLabel component="legend">
        Maximum allowed time difference between the expected collection time and the estimated
        collection time from TDS.
      </FormLabel>
      <TextField
        {...formikProps('collection_time_threshold')}
        id="outlined-full-width"
        label="Collection Time Threshold"
        type="number"
        variant="outlined"
        fullWidth={true}
        InputProps={{
          endAdornment: <InputAdornment position="end">mins</InputAdornment>,
          inputProps: { min: 1 }
        }}
        inputProps={{
          'aria-label': 'collection_time_threshold'
        }}
        disabled={isSubmitting}
      />
      <br />
      <FormLabel component="legend">Hubs enabled for the TDS integration.</FormLabel>
      <FormControl fullWidth={true}>
        <InputLabel id="selectedHubs-label"> Selected Hubs</InputLabel>
        <Select
          placeholder="Loading hubs.."
          {...formikProps('selectedHubs')}
          labelId="selectedHubs-label"
          id="selectedHubs"
          name="selectedHubs"
          multiple
          input={<OutlinedInput label="Select Hubs" />}
          renderValue={(selected) =>
            // Display the hub names corresponding to the selected hub IDs.
            hubs
              .filter((hub) => selected.includes(hub.id))
              .map((hub) => hub.name)
              .join(', ')
          }
        >
          {hubs.map((hub) => (
            <MenuItem key={hub.id} value={hub.id}>
              <Checkbox checked={values.selectedHubs.indexOf(hub.id) > -1} />
              <ListItemText primary={hub.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <div className={classes.formButtons}>
        <Button
          className={classes.margin}
          type="submit"
          variant="contained"
          size="large"
          color="primary"
          fullWidth={true}
          disabled={isSubmitting || isLoading}
        >
          {isLoading ? 'Loading...' : 'Save'}
        </Button>
      </div>
    </form>
  );
}

const validationSchema = Yup.object({
  collection_time_threshold: Yup.number('Enter maximum allowed travel distance')
    .required('Maximum allowed travel distance is required')
    .positive()
    .integer(),
  selectedHubs: Yup.array(),
  required_returns: Yup.boolean().required('Required returns setting is required')
});

export function EditTakealotSettingsComponent({
  collection_time_threshold,
  selectedHubs,
  required_returns,
  openBackdrop,
  closeBackdrop,
  showSnackbar,
  handleClose,
  updateSuccess
}) {
  const dispatch = useDispatch();

  const values = {
    collection_time_threshold,
    selectedHubs: selectedHubs.map((hub) => hub.id),
    required_returns: required_returns?.enabled ?? false
  };
  const submit = async (values, { setSubmitting }) => {
    try {
      openBackdrop();
      const result = await editTakealotSettings({
        collection_time_threshold: values.collection_time_threshold,
        hub_ids: values.selectedHubs,
        required_returns_enabled: values.required_returns
      });
      showSnackbar('success', 'Takelot Integration settings updated.');
      updateSuccess();
      dispatch({ type: SETTINGS_LOAD_SUCCESS, payload: result });
    } catch (error) {
      showSnackbar('error', error.message);
    } finally {
      setSubmitting(false);
      closeBackdrop();
      handleClose();
    }
  };

  return (
    <Formik initialValues={values} validationSchema={validationSchema} onSubmit={submit}>
      {(props) => <EditTakealotSettingsForm {...props} />}
    </Formik>
  );
}
