export const SETTINGS_LOAD_REQUEST = 'SETTINGS_LOAD_REQUEST';
export const SETTINGS_LOAD_SUCCESS = 'SETTINGS_LOAD_SUCCESS';
export const SETTINGS_LOAD_FAILURE = 'SETTINGS_LOAD_FAILURE';

export type TerminateOrders = {
  enabled: boolean;
  max_age: number;
};

export type OrderHistory = {
  hours: number;
  type: string;
};

export type AutomaticReturns = {
  enabled: boolean;
};

export type ROStartEndLocations = {
  start_at_branch: boolean;
  end_at_branch: boolean;
};

export type TakealotIntegrationSettings = {
  collection_time_threshold: number;
  hub_ids: string[];
  required_returns_enabled: boolean;
};
export type OrderNearby = {
  enabled: boolean;
  radius: number;
};

export type BranchLogo = {
  enabled: boolean;
};
