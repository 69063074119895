import {
  AutomaticReturns,
  OrderHistory,
  OrderNearby,
  ROStartEndLocations,
  TerminateOrders
} from '../../presentation/redux/types/settings-types';
import { IHideTripCost, Settings } from '../entities/settings-entity';
import { SettingsRepository } from '../repositories/settings-repository';

interface SettingsUsecase {
  GetSettings(): Promise<Settings>;
  EditRouteOptimisation(route_optimisation: object): Promise<Settings>;
  EditRatings(ratings: string[]): Promise<Settings>;
  EditAbandonmentReasons(reasons: string[]): Promise<Settings>;
  EditPartialDeliveryReasons(reasons: string[]): Promise<Settings>;
  EditTaskPauseReasons(reasons: string[]): Promise<Settings>;
  EditPooling(algorithm: string): Promise<Settings>;
  EditCluster(
    distance: number,
    size: number,
    lock_strategy: string,
    lock_time: number,
    collection_time_range: number
  ): Promise<Settings>;
  EditSms(sms_enabled: boolean): Promise<Settings>;
  EditVehicleTypes(types: string[]): Promise<Settings>;
  EditTerminateOrders(terminate_orders: TerminateOrders): Promise<Settings>;
  EditGeofence(geofence: object): Promise<Settings>;
  EditPoolingGeofence(pooling_geofence: object): Promise<Settings>;
  EditRecipientName(recipient_name: object): Promise<Settings>;
  EditTripSequencing(trip_sequencing: object): Promise<Settings>;
  EditOrderHistory(order_history: OrderHistory): Promise<Settings>;
  EditTripAppCreate(can_create_trip: object): Promise<Settings>;
  EditAutomaticReturns(automatic_returns: AutomaticReturns): Promise<Settings>;
  EditOrderNearby(order_nearby: OrderNearby): Promise<Settings>;
  EditROStartEndLocations(ro_start_end_locations: ROStartEndLocations): Promise<Settings>;
}

export class SettingsUsecaseImpl implements SettingsUsecase {
  settingsRepository: SettingsRepository;

  constructor(sr: SettingsRepository) {
    this.settingsRepository = sr;
  }

  GetSettings(): Promise<Settings> {
    return this.settingsRepository.GetSettings();
  }

  EditRouteOptimisation(route_optimisation: object): Promise<Settings> {
    return this.settingsRepository.EditRouteOptimisation(route_optimisation);
  }

  EditRatings(ratings: string[]): Promise<Settings> {
    return this.settingsRepository.EditRatings(ratings);
  }

  EditAbandonmentReasons(reasons: string[]): Promise<Settings> {
    return this.settingsRepository.EditAbandonmentReasons(reasons);
  }

  EditPartialDeliveryReasons(reasons: string[]): Promise<Settings> {
    return this.settingsRepository.EditPartialDeliveryReasons(reasons);
  }

  EditTaskPauseReasons(reasons: string[]): Promise<Settings> {
    return this.settingsRepository.EditTaskPauseReasons(reasons);
  }

  EditPooling(algorithm: string): Promise<Settings> {
    return this.settingsRepository.EditPooling(algorithm);
  }

  EditCluster(
    distance: number,
    size: number,
    lock_strategy: string,
    lock_time: number,
    collection_time_range: number
  ): Promise<Settings> {
    return this.settingsRepository.EditCluster(
      distance,
      size,
      lock_strategy,
      lock_time,
      collection_time_range
    );
  }

  EditSms(sms_enabled: boolean): Promise<Settings> {
    return this.settingsRepository.EditSms(sms_enabled);
  }

  EditVehicleTypes(types: string[]): Promise<Settings> {
    return this.settingsRepository.EditVehicleTypes(types);
  }

  EditTerminateOrders(terminate_orders: TerminateOrders): Promise<Settings> {
    return this.settingsRepository.EditTerminateOrders(terminate_orders);
  }

  EditQRTyping(qr_typing: boolean): Promise<Settings> {
    return this.settingsRepository.EditQRTyping(qr_typing);
  }

  EditGeofence(geofence: object): Promise<Settings> {
    return this.settingsRepository.EditGeofence(geofence);
  }

  EditPoolingGeofence(pooling_geofence: object): Promise<Settings> {
    return this.settingsRepository.EditPoolingGeofence(pooling_geofence);
  }

  EditRecipientName(recipient_name: object): Promise<Settings> {
    return this.settingsRepository.EditRecipientName(recipient_name);
  }

  EditTripSequencing(trip_sequencing: object): Promise<Settings> {
    return this.settingsRepository.EditTripSequencing(trip_sequencing);
  }

  EditOrderHistory(order_history: OrderHistory): Promise<Settings> {
    return this.settingsRepository.EditOrderHistory(order_history);
  }
  EditTripAppCreate(can_create_trip: object): Promise<Settings> {
    return this.settingsRepository.EditTripAppCreate(can_create_trip);
  }
  EditCanRetryAbandoned(can_retry_abandoned: object): Promise<Settings> {
    return this.settingsRepository.EditCanRetryAbandoned(can_retry_abandoned);
  }
  EditAutomaticReturns(automatic_returns: AutomaticReturns): Promise<Settings> {
    return this.settingsRepository.EditAutomaticReturns(automatic_returns);
  }

  EditOrderNearby(order_nearby: OrderNearby): Promise<Settings> {
    return this.settingsRepository.EditOrderNearby(order_nearby);
  }

  EditROStartEndLocations(ro_start_end_locations: object): Promise<Settings> {
    return this.settingsRepository.EditROStartEndLocations(ro_start_end_locations);
  }
  EditHideTripCost(hide_trip_cost: IHideTripCost): Promise<Settings> {
    return this.settingsRepository.EditHideTripCost(hide_trip_cost);
  }
  EditScopes(scopes: string[]): Promise<Settings> {
    return this.settingsRepository.EditScopes(scopes);
  }
}
