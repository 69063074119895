export const OrderEvents = {
  collected: false,
  in_transit: false,
  arrived: false,
  delivered: false,
  abandoned: false,
  returned: false,
  cancelled: false,
  near_by: false
};

export const TripEvents = {
  manual_assigned: true,
  auto_assigned: true,
  accepted: false,
  rejected: false,
  arrived: false,
  started: false,
  completed: false,
  cancelled: false,
  arrived_at_collection_point: true,
  in_progress: true
};

export const TaskEvents = {
  collected: false,
  started: false,
  completed: false,
  abandoned: false,
  returned: false,
  arrived: false,
  cancelled: false
};

export const DriverAppEvents = {
  online: false,
  offline: false,
  onlunch: false,
  endlunch: false
};

export const WalletEvents = {
  completed: false
};

export const TransactionEvents = {
  summary: false
};
