// @ts-check
import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Paper, Grid } from '@material-ui/core';

import LocationOnIcon from '@material-ui/icons/LocationOn';
import IconButton from '@material-ui/core/IconButton/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import AvatarUpload from './LogoUpload';
import { useDispatch, useSelector } from 'react-redux';
import { createBranchAction, editBranchAction } from '../redux/actions/branch-actions';
import { CameraAlt as MuiCameraAlt } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    cursor: 'pointer'
  },
  icon: {
    fontSize: '8em'
  }
}));

export function BranchMapComponent({ branch }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { loading } = useSelector((state) => state.loading);

  const [open, setOpen] = React.useState(false);
  const [branchLogo, setBranchLogo] = React.useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const uploadImage = (event) => {
    event.preventDefault();

    dispatch(
      editBranchAction(
        branch.id,
        branch.name,
        branch.store_code,
        branch.dashboard_url,
        branch.contact,
        { handleClose },
        branchLogo
      )
    );
  };

  return (
    <>
      <Paper className={classes.paper} elevation={0}>
        <Grid container spacing={2} justify={'center'} direction={'column'} alignItems={'center'}>
          <Grid container justify={'flex-end'} style={{ paddingRight: '20px' }}>
            {!open && (
              <IconButton edge="end" aria-label="comments" onClick={handleClickOpen}>
                <EditIcon />
              </IconButton>
            )}
            {open && (
              <IconButton edge="end" aria-label="comments" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            )}
          </Grid>
          {!open && (
            <Grid item>
              <Avatar
                alt="Avatar"
                src={branch.logo}
                style={{
                  width: '250px',
                  height: '250px',
                  marginBottom: '20px'
                }}
                imgProps={{
                  style: {
                    maxHeight: '100%',
                    maxWidth: '100%',
                    objectFit: 'cover'
                  }
                }}
              >
                <MuiCameraAlt style={{ fontSize: 100 }} />
              </Avatar>
            </Grid>
          )}

          {open && (
            <Grid item>
              <AvatarUpload
                imageLoaded={setBranchLogo}
                currentImage={branch.logo}
                onSubmit={uploadImage}
                processing={loading}
              />
            </Grid>
          )}

          <Grid item>
            <b>{branch.name}</b>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}
