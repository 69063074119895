import { UserRepository } from '../repositories/user-repository';
import { User } from '../entities/user-entity';

export interface UserUsecase {
  CreateUser(
    firstname: string,
    lastname: string,
    email: string,
    mobile_no: string,
    password: string,
    scopes: string[]
  ): Promise<User>;
  EditUser(user_id: string, firstname: string, lastname: string): Promise<User>;
  EditUserEmail(user_id: string, email: string): Promise<User>;
  EditUserPhone(user_id: string, mobile_no: string): Promise<User>;
  EditUserPassword(user_id: string, password: string): Promise<User>;
  EditUserScopes(user_id: string, scopes: string[]): Promise<User>;
  GetUsers(): Promise<User[]>;
  GetUser(user_id: string): Promise<User>;
  GrantAdminRights(user_id: string): Promise<User>;
  RevokeAdminRights(user_id: string): Promise<User>;
  GrantFleetAccess(user_id: string): Promise<User>;
  RevokeFleetAccess(user_id: string): Promise<User>;
  GrantHubAccess(user_id: string, hub_id: string): Promise<User>;
  RevokeHubAccess(user_id: string, hub_id: string): Promise<User>;
  GrantRegionAccess(user_id: string, region_id: string): Promise<User>;
  RevokeRegionAccess(user_id: string, region_id: string): Promise<User>;
  UploadUserImage(client_id: string, user_id: string, file: any): Promise<User>;
}

export class UserUsecaseImpl implements UserUsecase {
  userRepository: UserRepository;

  constructor(ur: UserRepository) {
    this.userRepository = ur;
  }

  CreateUser(
    firstname: string,
    lastname: string,
    email: string,
    mobile_no: string,
    password: string,
    scopes: string[]
  ): Promise<User> {
    return this.userRepository.CreateUser(firstname, lastname, email, mobile_no, password, scopes);
  }

  EditUser(user_id: string, firstname: string, lastname: string): Promise<User> {
    return this.userRepository.EditUser(user_id, firstname, lastname);
  }

  EditUserEmail(user_id: string, email: string): Promise<User> {
    return this.userRepository.EditUserEmail(user_id, email);
  }

  EditUserPhone(user_id: string, mobile_no: string): Promise<User> {
    return this.userRepository.EditUserPhone(user_id, mobile_no);
  }

  EditUserPassword(user_id: string, password: string): Promise<User> {
    return this.userRepository.EditUserPassword(user_id, password);
  }

  EditUserScopes(user_id: string, scopes: string[]): Promise<User> {
    return this.userRepository.EditUserScopes(user_id, scopes);
  }

  GetUsers(): Promise<User[]> {
    return this.userRepository.GetUsers();
  }

  GetUser(user_id: string): Promise<User> {
    return this.userRepository.GetUser(user_id);
  }

  GrantAdminRights(user_id: string): Promise<User> {
    return this.userRepository.GrantAdminRights(user_id);
  }

  RevokeAdminRights(user_id: string): Promise<User> {
    return this.userRepository.RevokeAdminRights(user_id);
  }

  GrantFleetAccess(user_id: string): Promise<User> {
    return this.userRepository.GrantFleetAccess(user_id);
  }

  RevokeFleetAccess(user_id: string): Promise<User> {
    return this.userRepository.RevokeFleetAccess(user_id);
  }

  GrantHubAccess(user_id: string, hub_id: string): Promise<User> {
    return this.userRepository.GrantHubAccess(user_id, hub_id);
  }

  RevokeHubAccess(user_id: string, hub_id: string): Promise<User> {
    return this.userRepository.RevokeHubAccess(user_id, hub_id);
  }

  GrantRegionAccess(user_id: string, region_id: string): Promise<User> {
    return this.userRepository.GrantRegionAccess(user_id, region_id);
  }

  RevokeRegionAccess(user_id: string, region_id: string): Promise<User> {
    return this.userRepository.RevokeRegionAccess(user_id, region_id);
  }

  UploadUserImage(client_id: string, user_id: string, file: any): Promise<User> {
    return this.userRepository.UploadUserImage(client_id, user_id, file);
  }
}