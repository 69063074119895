import { IPaymentProvider } from '../../../domain/entities/branch-entity';
import { useStyles } from './payment_providers.styles';
import React, { useReducer } from 'react';
import {
  paymentFieldsActionTypes,
  paymentFieldsInitialState,
  paymentFieldsReducer
} from './payment_fields.reducer';
import { editPaymentFields } from '../../redux/actions/branch-actions';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { IconButton, ListItemSecondaryAction, Modal } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import Backdrop from '@material-ui/core/Backdrop/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { SnackbarComponent } from '../../../../../core/utils/components/snackbar-component';
import Box from '@material-ui/core/Box';
import { Formik } from 'formik';
import PaymentFieldsForm from './payment_fields_form.component';
import Button from '@material-ui/core/Button/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography/Typography';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';

export function EditPaymentFields({
  branchId,
  paymentProvider
}: {
  paymentProvider: IPaymentProvider;
  branchId: string;
}) {
  const classes = useStyles();
  const reduxDispatch = useDispatch();
  const [state, dispatch] = useReducer(paymentFieldsReducer, paymentFieldsInitialState);

  const handleConfirmationOpen = () =>
    dispatch({
      type: paymentFieldsActionTypes.SET_OPEN,
      payload: true
    });
  const handleConfirmationClose = () =>
    dispatch({
      type: paymentFieldsActionTypes.SET_OPEN,
      payload: false
    });

  const showSnackbar = (status = 'success', description = 'Custom fields updated') => {
    dispatch({
      type: paymentFieldsActionTypes.OPEN_SNACKBAR,
      payload: {
        status,
        description
      }
    });
  };

  const closeSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    dispatch({
      type: paymentFieldsActionTypes.CLOSE_SNACKBAR
    });
  };

  const closeBackdrop = () => {
    dispatch({
      type: paymentFieldsActionTypes.SET_BACKDROP,
      payload: false
    });
  };

  const validationSchema = Yup.object({
    merchant_id: Yup.string('Enter the store id')
      .min(1, 'Must be at least 1 character')
      .max(50, 'Must be less than 50 characters')
      .required("Please enter the Store ID"),
    acquirer_id: Yup.string('Enter the pos terminal id')
      .min(1, 'Must be at least 1 character')
      .max(50, 'Must be less than 50 characters')
      .required("Please enter the POS Terminal ID"),
    acquirer_name: Yup.string('Enter the chain')
      .min(1, 'Must be at least 1 character')
      .max(50, 'Must be less than 50 characters')
      .required("Please enter the chain name")
  });

  const submit = (data) => {
    closeBackdrop();
    const props = { closeBackdrop, showSnackbar };
    reduxDispatch(
      editPaymentFields(branchId, data.merchant_id, data.acquirer_id, data.acquirer_name, props)
    );
    handleConfirmationClose();
  };

  const values: IPaymentProvider = {
    merchant_id: paymentProvider?.merchant_id,
    acquirer_id: paymentProvider?.acquirer_id,
    acquirer_name: paymentProvider?.acquirer_name
  };

  return (
    <>
      <List data-testid="EDIT_PAYMENT_DETAILS" className={classes.root}>
        <ListItem>
          <Fields paymentFields={paymentProvider} />
          <ListItemSecondaryAction>
            <IconButton
              className={classes.margin}
              edge="end"
              aria-label="comments"
              onClick={handleConfirmationOpen}
            >
              <EditIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      </List>
      <>
        <Backdrop
          className={classes.backdrop}
          open={state.backdrop}
          invisible={false}
          onClick={closeBackdrop}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <SnackbarComponent
          open={state.snackbar}
          onClose={closeSnackbar}
          severity={state.message.status}
          message={state.message.description}
        />
      </>

      <Modal
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={state.open}
        onClose={handleConfirmationClose}
        BackdropComponent={Backdrop}
      >
        <Box className={classes.modal}>
          <h2 id="unstyled-modal-title">Payment Provider Configuration</h2>

          <Formik
            initialValues={values}
            validationSchema={validationSchema}
            onSubmit={submit}
            component={PaymentFieldsForm}
          />

          <Button
            className={classes.marginCancel}
            variant="outlined"
            size="large"
            color="secondary"
            fullWidth={true}
            onClick={handleConfirmationClose}
          >
            Cancel
          </Button>
        </Box>
      </Modal>
    </>
  );
}

function Fields({ paymentFields }: { paymentFields: IPaymentProvider }) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <Typography variant="subtitle2" component="div">
          Store ID : {paymentFields?.merchant_id || 'N/A'}
        </Typography>
      </Grid>
      <Grid item xs={12} md={4}>
        <Typography variant="subtitle2" component="div">
          POS Terminal ID : {paymentFields?.acquirer_id || 'N/A'}
        </Typography>
      </Grid>
      <Grid item xs={12} md={4}>
        <Typography variant="subtitle2" component="div">
          Chain : {paymentFields?.acquirer_name || 'N/A'}
        </Typography>
      </Grid>
    </Grid>
  );
}
