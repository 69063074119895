import { Hub } from '../../../hubs/domain/entities/hub-entity';

export class GeoPoint {
  latitude: number;
  longitude: number;

  constructor(latitude: number, longitude: number) {
    this.latitude = latitude;
    this.longitude = longitude;
  }
}

// class ThreePlPartner {
//   name: string;
//   hooks: object;

//   /**
//    * ThreePlPartner constructor
//    * @param name
//    * @param hooks
//    */
//   constructor(name: string, hooks: object) {
//     this.name = name;
//     this.hooks = hooks;
//   }
// }
export interface IPaymentProvider {
  acquirer_name: string;
  merchant_id: string;
  acquirer_id: string;
}
export interface IBranch {
  id: string;
  hub_id: string;
  name: string;
  contact: string;
  store_code: string;
  dashboard_url: string;
  address: string;
  location: GeoPoint;
  hub?: Hub;
  payment_fields?: PaymentProvider[];
  logo?: string;
}

export class PaymentProvider implements IPaymentProvider {
  acquirer_name: string;
  merchant_id: string;
  acquirer_id: string;

  constructor(args: IPaymentProvider) {
    this.acquirer_name = args.acquirer_name;
    this.merchant_id = args.merchant_id;
    this.acquirer_id = args.acquirer_id;
  }

  public static empty() {
    return new PaymentProvider({
      acquirer_name: 'TJ',
      merchant_id: '',
      acquirer_id: ''
    });
  }
}
export class Branch implements IBranch {
  id: string;
  hub_id: string;
  name: string;
  contact: string;
  store_code: string;
  dashboard_url: string;
  address: string;
  location: GeoPoint;
  hub?: Hub;
  payment_fields?: IPaymentProvider[];
  logo?: string;
  // three_pl_partners?: ThreePlPartner;

  constructor(args: IBranch) {
    this.id = args.id;
    this.hub_id = args.hub_id;
    this.name = args.name;
    this.contact = args.contact;
    this.store_code = args.store_code;
    this.dashboard_url = args.dashboard_url;
    this.address = args.address;
    this.location = new GeoPoint(args.location.latitude, args.location.longitude);
    this.hub = args.hub && new Hub(args.hub);
    this.payment_fields = args.payment_fields;
    this.logo = args.logo;
    // this.three_pl_partners = new ThreePlPartner(
    //   args.three_pl_partners.name,
    //   args.three_pl_partners.hooks
    // );
  }
}
