import { Branch } from '../../domain/entities/branch-entity';
import { BranchRepository } from '../../domain/repositories/branch-repository';
import { changeHub } from '../datasources/change-hub';
import { createBranch } from '../datasources/create-branch';
import { editBranch } from '../datasources/edit-branch';
import { editBranchAddress } from '../datasources/edit-branch-address';
import { getBranch } from '../datasources/get-branch';
import { getBranches } from '../datasources/get-branches';
import { editPaymentFields } from '../datasources/edit-custom-fields';
import { editBranchThreePl } from '../datasources/edit-branch-three-pl';
import { deletBranchThreePl } from '../datasources/delete-branch-three-pl';

export class BranchRepositoryImpl implements BranchRepository {
  async CreateBranch(
    hub_id: string,
    name: string,
    contact: string,
    store_code: string,
    dashboard_url: string,
    address: string,
    location: any,
    branch_logo?: File | null
  ): Promise<Branch> {
    const result = await createBranch(
      hub_id,
      name,
      contact,
      store_code,
      dashboard_url,
      address,
      location,
      branch_logo
    );

    return new Branch(result);
  }

  async EditBranch(
    branch_id: string,
    name: string,
    store_code: string,
    dashboard_url: string,
    contact: string,
    branch_logo?: File | null
  ): Promise<Branch> {
    const result = await editBranch(
      branch_id,
      name,
      store_code,
      dashboard_url,
      contact,
      branch_logo
    );

    return new Branch(result);
  }

  async EditAddress(branch_id: string, address: string, location: any): Promise<Branch> {
    const result = await editBranchAddress(branch_id, address, location);

    return new Branch(result);
  }

  async GetBranch(branch_id: string): Promise<Branch> {
    const result = await getBranch(branch_id);

    return new Branch(result);
  }

  async GetBranches(): Promise<Branch[]> {
    const result = await getBranches();

    return result.map((branch: any) => new Branch(branch));
  }

  async ChangeHub(branch_id: string, hub_id: string): Promise<Branch> {
    const result = await changeHub(branch_id, hub_id);

    return new Branch(result);
  }

  async EditPaymentFields(
    branch_id: string,
    merchant_id: string,
    acquirer_id: string,
    acquirer_name: string
  ): Promise<Branch> {
    const result = await editPaymentFields(branch_id, merchant_id, acquirer_id, acquirer_name);
    return new Branch(result);
  }

  async EditBranchThreePl(branch_id: string, partner_id: string): Promise<Branch> {
    const result = await editBranchThreePl(branch_id, partner_id);
    return new Branch(result);
  }
  async DeleteBranchThreePl(branch_id: string): Promise<Branch> {
    const result = await deletBranchThreePl(branch_id);
    return new Branch(result);
  }
}
