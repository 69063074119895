import { Hub } from '../entities/hub-entity';
import { HubRepository } from '../repositories/hub-repository';

export interface HubUsecase {
  CreateHub(name: string): Promise<Hub>;
  EditHub(hub_id: string, name: string): Promise<Hub>;
  GetHub(hub_id: string): Promise<Hub>;
  GetHubs(): Promise<Hub[]>;
  RemoveHubFromRegion(region_id: string, hub_id: string): Promise<Hub>;
}

export class HubUsecaseImpl implements HubUsecase {
  hubRepository: HubRepository;

  constructor(hr: HubRepository) {
    this.hubRepository = hr;
  }

  CreateHub(name: string): Promise<Hub> {
    return this.hubRepository.CreateHub(name);
  }

  EditHub(hub_id: string, name: string): Promise<Hub> {
    return this.hubRepository.EditHub(hub_id, name);
  }

  GetHub(hub_id: string): Promise<Hub> {
    return this.hubRepository.GetHub(hub_id);
  }

  GetHubs(): Promise<Hub[]> {
    return this.hubRepository.GetHubs();
  }

  RemoveHubFromRegion(region_id: string, hub_id: string): Promise<Hub> {
    return this.hubRepository.RemoveHubFromRegion(region_id, hub_id);
  }

  GrantHubCrossClustering(hub_id: string): Promise<Hub> {
    return this.hubRepository.GrantHubCrossClustering(hub_id);
  }

  RevokeHubCrossClustering(hub_id: string): Promise<Hub> {
    return this.hubRepository.RevokeHubCrossClustering(hub_id);
  }
}
