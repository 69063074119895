// @ts-check
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import {
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Button,
  Backdrop,
  CircularProgress
} from '@material-ui/core';

import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import {
  grantHubCrossClusteringAction,
  revokeHubCrossClusteringAction
} from '../redux/actions/hub-actions';

import { SnackbarComponent } from '../../../../core/utils/components/snackbar-component';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(2)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  granted: {
    color: '#4caf50'
  }
}));

export function HubSettingsComponent({ hub }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [backdrop, setBackdrop] = useState(false);
  const [snackbar, setSnackbar] = useState(false);
  const [message, setMessage] = useState({
    status: 'success',
    description: 'Setting updated'
  });

  const showSnackbar = (status = 'success', description = 'Setting updated') => {
    setSnackbar(true);
    setMessage({ status, description });
    setBackdrop(false);
  };

  const closeSnackbar = (_event, reason) => {
    if (reason === 'clickaway') return;
    setSnackbar(false);
  };

  const closeBackdrop = () => setBackdrop(false);

  const enableSetting = () => {
    setBackdrop(true);
    const props = { closeBackdrop, showSnackbar };
    dispatch(grantHubCrossClusteringAction(hub.id, props));
  };

  const disableSetting = () => {
    setBackdrop(true);
    const props = { closeBackdrop, showSnackbar };
    dispatch(revokeHubCrossClusteringAction(hub.id, props));
  };

  return (
    <>
      <List className={classes.root}>
        <ListItem>
          <ListItemIcon>
            {hub.cross_clustering?.enabled ? (
              <FiberManualRecordIcon className={classes.granted} />
            ) : (
              <FiberManualRecordIcon color="error" />
            )}
          </ListItemIcon>
          <ListItemText primary={<small>Hub Cross Clustering</small>} />
          <ListItemSecondaryAction>
            {hub.cross_clustering?.enabled ? (
              <Button variant="outlined" size="small" color="secondary" onClick={disableSetting}>
                Disable
              </Button>
            ) : (
              <Button variant="contained" size="small" color="secondary" onClick={enableSetting}>
                Enable
              </Button>
            )}
          </ListItemSecondaryAction>
        </ListItem>
      </List>

      <Backdrop className={classes.backdrop} open={backdrop} onClick={closeBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <SnackbarComponent
        open={snackbar}
        onClose={closeSnackbar}
        severity={message.status}
        message={message.description}
      />
    </>
  );
}
