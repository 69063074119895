import fetch from 'isomorphic-fetch';
import { config } from '../../../../core/config/config';
import store from '../../../../core/redux/store';

export const createBranch = async (
  hub_id: string,
  name: string,
  contact: string,
  store_code: string,
  dashboard_url: string,
  address: string,
  location: any,
  branch_logo?: File | null
): Promise<any> => {
  try {
    const state = store.getState();
    const token = state.token.token;

    const uri = `${config.uri.base_url}/branches/create`;

    const formData = new FormData();
    formData.append('hub_id', hub_id);
    formData.append('name', name);
    formData.append('contact', contact);
    formData.append('store_code', store_code);
    formData.append('dashboard_url', dashboard_url);
    formData.append('address', address);
    formData.append('location', JSON.stringify(location));
    if (branch_logo) {
      formData.append('branch_logo', branch_logo);
    }

    const options = {
      method: 'POST',
      headers: new Headers({
        Authorization: `Bearer ${token}`
      }),
      body: formData
    };

    const response = await fetch(uri, options);
    const result = await response.json();

    if (!response.ok) {
      throw new Error(result.message || 'Something went wrong');
    }

    return result;
  } catch (err) {
    throw err;
  }
};
