// @ts-check
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { getBranchAction } from '../redux/actions/branch-actions';

import { makeStyles } from '@material-ui/core/styles';

import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';

import Grid from '@material-ui/core/Grid';
import { LoadingComponent } from '../components/loading-component';
import { BranchInformationComponent } from '../components/branch-information-component';
import { BranchStoreCodeComponent } from '../components/branch-store-code-component';
import { BranchDashboardUrlComponent } from '../components/branch-dashboard-url-component';
import { BranchMapComponent } from '../components/branch-map-component';
import { AssignedHubComponent } from '../components/assigned-hub-component';
import { getHubsAction } from '../../../hubs/presentation/redux/actions/hub-actions';
import { BranchAddressComponent } from '../components/branch-address-component';
import { BackNavigationComponent } from '../../../../core/routes/components/back-navigation-component';
import PaymentProvidersComponent from '../components/payment_providers/payment_providers.component';
// import { AssignedThirdPartyComponent } from '../components/branch-3pl-partners-component';
// import { BranchRemoveThirdPartyPartnerComponent } from '../components/branch-remove-3pl-partner-component';
// import { getSettingsAction } from '../../../settings/presentation/redux/actions/settings-actions';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0),
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
  container: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  sectionHeading: {
    margin: theme.spacing(3)
  },
  userHeader: {
    textAlign: 'center'
  },
  userLogo: {
    height: '10rem',
    width: '10rem',
    objectFit: 'contain'
  },
  large: {
    height: '8rem',
    width: '8rem',
    objectFit: 'contain'
  },
  margin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },

  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    cursor: 'pointer'
  }
}));

function Branch({ branch, me }) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <BranchMapComponent branch={branch} />
      </Grid>

      <Grid item xs={12} md={8}>
        <BranchInformationComponent branch={branch} />
        <BranchStoreCodeComponent branch={branch} />
        <BranchDashboardUrlComponent branch={branch} />
        <BranchAddressComponent branch={branch} />
        <AssignedHubComponent branch={branch} />
        {me.organization?.allow_custom_payment_fields && (
          <PaymentProvidersComponent branch={branch} />
        )}
      </Grid>
    </Grid>
  );
}

export default function BranchPage() {
  // @ts-ignore
  const { id } = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();

  const { branch, loading, success } = useSelector((state) => state.branch);
  const { me } = useSelector((state) => state.me);

  useEffect(() => {
    dispatch(getBranchAction(id));
    dispatch(getHubsAction());
  }, [dispatch, id]);

  return (
    <>
      <React.Fragment>
        <CssBaseline />
        <Container className={classes.container}>
          {loading && <LoadingComponent />}
          {!loading && success && (
            <>
              <BackNavigationComponent
                page="Branch"
                uri="/branches"
                path={[
                  {
                    page: 'Home',
                    uri: '/'
                  },
                  {
                    page: 'Branches',
                    uri: '/branches'
                  }
                ]}
              />
              <Branch branch={branch} me={me} />
            </>
          )}
        </Container>
      </React.Fragment>
    </>
  );
}
