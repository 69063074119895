class Region {
  id: string;
  name: string;

  constructor(id: string, name: string) {
    this.id = id;
    this.name = name;
  }
}

class Hub {
  id: string;
  name: string;

  constructor(id: string, name: string) {
    this.id = id;
    this.name = name;
  }
}

class Dos {
  hubs: Hub[];
  regions: Region[];

  constructor(hubs: any[], regions: any[]) {
    this.hubs = hubs.map((hub) => new Hub(hub.id, hub.name));
    this.regions = regions.map((region) => new Region(region.id, region.name));
  }
}

class Permissions {
  administrator: boolean;
  fleet: boolean;
  dos: any;
  scopes: string[];

  constructor(administrator: boolean = false, fleet: boolean = false, dos: any = undefined, scopes: string[] = []) {
    this.administrator = administrator;
    this.fleet = fleet;
    this.dos = dos && new Dos(dos.hubs, dos.regions);
    this.scopes = scopes;
  }
}

export class User {
  id: string;
  firstname: string;
  lastname: string;
  image: string;
  email: string;
  mobile_no: string;
  status: string;
  permissions: Permissions;

  /**
   * User entity constructor
   * @constructor
   * @param user
   */
  constructor(user: any, permissions: any = undefined) {
    this.id = user.id;
    this.firstname = user.firstname;
    this.lastname = user.lastname;
    this.image = user.image;
    this.email = user.email;
    this.mobile_no = user.mobile_no;
    this.status = user.status;
    this.permissions =
      permissions && new Permissions(permissions.administrator, permissions.fleet, permissions.dos, permissions.scopes);
  }
}