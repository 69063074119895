// @ts-nocheck
import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';

import IconButton from '@material-ui/core/IconButton';

import Grid from '@material-ui/core/Grid';

import Backdrop from '@material-ui/core/Backdrop/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { SnackbarComponent } from '../../../../../core/utils/components/snackbar-component';
import Divider from '@material-ui/core/Divider';

import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import TimerIcon from '@material-ui/icons/Timer';
import { getContract } from '../../../data/datasources/get-contract';
import { EditTakealotSettingsComponent } from './edit-takealot-settings';
import { useDispatch } from 'react-redux';
import { refreshMeAction } from '../../../../me/presentation/redux/actions/me-actions';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // maxWidth: 752
    margin: theme.spacing(2, 0)
  },
  demo: {
    backgroundColor: theme.palette.background.paper
  },
  title: {
    margin: theme.spacing(4, 0, 2)
  },
  form: {
    margin: theme.spacing(2, 2, 2, 2)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}));

function TakealotSettings({
  organization,
  required_returns,
  openBackdrop,
  closeBackdrop,
  showSnackbar
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [contract, setContract] = useState({ id: '', promisor: '', promisee: '', hubs: [] });
  const dispatch = useDispatch();

  const toggleOpen = () => {
    setOpen(!open);
  };

  const fetchContract = async () => {
    const contract = await getContract(organization.integration_flow.contract);
    setContract(contract);
  };

  const updateSuccess = async () => {
    dispatch(refreshMeAction());
    fetchContract();
  };

  useEffect(() => {
    fetchContract();
  }, [organization.integration_flow.contract]);

  return (
    <div className={classes.demo}>
      <List>
        <ListItem>
          <ListItemText primary="Takelot Integration settings" secondary={null} />
          <ListItemSecondaryAction>
            <IconButton edge="end" aria-label="comments" onClick={toggleOpen}>
              {open ? <CloseIcon /> : <EditIcon />}
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      </List>
      <Divider />

      {!open && (
        <div className={classes.form}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <List>
                <ListItem>
                  <ListItemText
                    primary="Collection Time Threshold"
                    secondary={`${organization.integration_flow?.settings?.collection_time_threshold} minutes`}
                  />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="comments" onClick={toggleOpen}>
                      <TimerIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12} md={6}>
              <List>
                <ListItem>
                  <ListItemText
                    primary="Required Returns"
                    secondary={`${required_returns?.enabled ? 'Enabled' : 'Disabled'}`}
                  />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="comments" onClick={toggleOpen}>
                      <TimerIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12} md={12}>
              <List>
                <ListItem>
                  {/* <ListItemText primary="Contract" secondary={`${contract.id}`} /> */}
                  <ListItemText
                    primary="Selected Hubs"
                    secondary={
                      contract.hubs.length
                        ? contract.hubs.map((hub) => hub.name).join(', ')
                        : 'No hubs selected'
                    }
                  />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="comments" onClick={toggleOpen}>
                      <TimerIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </div>
      )}

      {open && (
        <>
          <div className={classes.form}>
            <EditTakealotSettingsComponent
              collection_time_threshold={
                organization.integration_flow?.settings?.collection_time_threshold
              }
              selectedHubs={contract.hubs}
              required_returns={required_returns}
              isDisabled={!open}
              openBackdrop={openBackdrop}
              closeBackdrop={closeBackdrop}
              showSnackbar={showSnackbar}
              handleClose={toggleOpen}
              updateSuccess={updateSuccess}
            />
          </div>
        </>
      )}
    </div>
  );
}

export function TakealotSettingsComponent({ organization, required_returns }) {
  const classes = useStyles();

  const [backdrop, setBackdrop] = useState(false);
  const [snackbar, setSnackbar] = useState(false);
  const [message, setMessage] = useState({
    status: 'success',
    description: 'Updated'
  });

  const showSnackbar = (status = 'success', description = 'Updated') => {
    setSnackbar(true);
    setMessage({
      status,
      description
    });
    setBackdrop(false);
  };

  const closeSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbar(false);
  };

  const closeBackdrop = () => {
    setBackdrop(false);
  };

  const openBackdrop = () => {
    setBackdrop(true);
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <TakealotSettings
            organization={organization}
            required_returns={required_returns}
            openBackdrop={openBackdrop}
            closeBackdrop={closeBackdrop}
            showSnackbar={showSnackbar}
          />
        </Grid>
      </Grid>

      <>
        <Backdrop
          className={classes.backdrop}
          open={backdrop}
          invisible={false}
          onClick={closeBackdrop}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <SnackbarComponent
          open={snackbar}
          onClose={closeSnackbar}
          severity={message.status}
          message={message.description}
        />
      </>
    </div>
  );
}
