// @ts-check
import React from 'react';

import { useDispatch } from 'react-redux';
import { editOrderNearbyAction } from '../redux/actions/settings-actions';

import { Formik } from 'formik';
import * as Yup from 'yup';

import { makeStyles } from '@material-ui/core/styles';
import { TextField, Button, ListItemText } from '@material-ui/core';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    '& > *': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    }
  },
  sectionHeading: {
    margin: theme.spacing(2)
  },
  margin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  formButtons: {
    marginTop: theme.spacing(0),
    paddingBottom: theme.spacing(1)
  },
  errorText: {
    color: '#f44336',
    textAlign: 'center',
    display: 'block',
    margin: theme.spacing(3)
  },
  switchButton: {
    float: 'left',
    position: 'absolute',
    left: '-90px'
  },
  geofencingText: {
    float: 'left',
    position: 'absolute',
    left: '-210px'
  }
}));

function EditOrderNearbyForm(props) {
  const classes = useStyles();

  const { values, errors, touched, handleSubmit, handleChange, handleBlur } = props;

  /**
   * @param {string} name
   */
  const formikProps = (name, initialValue = '') => ({
    name: name,
    value: typeof values[name] !== 'undefined' ? values[name] : initialValue,
    onChange: handleChange,
    onBlur: handleBlur,
    error: touched[name] && Boolean(errors[name]),
    helperText: touched[name] ? errors[name] : ''
  });

  return (
    <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit}>
      <FormControl component="fieldset">
        <RadioGroup aria-label="enabled" {...formikProps('enabled')}>
          <FormControlLabel value={'true'} control={<Radio />} label="Yes" />
          <FormControlLabel value={'false'} control={<Radio />} label="No" />
        </RadioGroup>
      </FormControl>
      <ListItemText secondary="Send a webhook event when a driver gets within this radius." />
      <TextField
        {...formikProps('radius')}
        id="outlined-full-width"
        label="Radius Distance"
        type="number"
        variant="outlined"
        fullWidth={true}
        inputProps={{
          'aria-label': 'radius'
        }}
        InputProps={{
          endAdornment: <InputAdornment position="end">m</InputAdornment>,
          inputProps: { min: 1 }
        }}
      />

      <div className={classes.formButtons}>
        <>
          <Button
            className={classes.margin}
            type="submit"
            variant="contained"
            size="large"
            color="primary"
            fullWidth={true}
          >
            Save Changes
          </Button>
        </>
      </div>
    </form>
  );
}

const validationSchema = Yup.object({
  enabled: Yup.boolean('Select a setting').required('Enabled value setting is required'),
  radius: Yup.number('Enter radius distance').min(1).required('Priority radius is required')
});

export function EditOrderNearbyFormComponent({
  order_nearby,
  openBackdrop,
  closeBackdrop,
  showSnackbar,
  handleClose
}) {
  const dispatch = useDispatch();

  const values = {
    enabled: String(order_nearby.enabled),
    radius: Number(order_nearby.radius)
  };

  const submit = (data, actions) => {
    data.enabled = data.enabled === 'true' ? true : false;
    openBackdrop();
    const { resetForm } = actions;
    const props = { resetForm, closeBackdrop, showSnackbar, handleClose };
    dispatch(editOrderNearbyAction(data, props));
  };

  return (
    <Formik initialValues={values} validationSchema={validationSchema} onSubmit={submit}>
      {(props) => <EditOrderNearbyForm {...props} />}
    </Formik>
  );
}
