import { Avatar, Button as MuiButton, CircularProgress, Typography } from '@material-ui/core';
import {
  CloudUpload as MuiCloudUpload,
  Delete as MuiDelete,
  Save as MuiSave,
  CameraAlt as MuiCameraAlt
} from '@material-ui/icons';
import React, { createRef, useState } from 'react';

const AvatarUpload = ({ imageLoaded, currentImage, onSubmit = null, processing = false }) => {
  const [image, _setImage] = useState(null);
  const inputFileRef = createRef(null);

  const cleanup = () => {
    URL.revokeObjectURL(image);
    inputFileRef.current.value = null;
  };

  const setImage = (newImage) => {
    if (image) {
      cleanup();
    }
    _setImage(newImage);
  };

  const handleOnChange = (event) => {
    const newImage = event.target?.files?.[0];

    if (newImage) {
      const objUrl = URL.createObjectURL(newImage);
      setImage(objUrl);

      imageLoaded(newImage);
    }
  };

  /**
   *
   * @param {React.MouseEvent<HTMLButtonElement, MouseEvent>} event
   */
  const handleClick = (event) => {
    if (image) {
      event.preventDefault();
      setImage(null);
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Avatar
        alt="Avatar"
        src={image || currentImage}
        style={{
          width: '250px',
          height: '250px',
          marginBottom: '20px'
        }}
        imgProps={{
          style: {
            maxHeight: '100%',
            maxWidth: '100%',
            objectFit: 'cover'
          }
        }}
      >
        {!image && !currentImage && <MuiCameraAlt style={{ fontSize: 100 }} />}
      </Avatar>
      <input
        ref={inputFileRef}
        accept="image/*"
        hidden
        id="avatar-image-upload"
        type="file"
        onChange={handleOnChange}
      />
      <label htmlFor="avatar-image-upload">
        <MuiButton
          variant="contained"
          color={image ? 'secondary' : 'primary'}
          component="span"
          mb={2}
          disabled={processing}
          onClick={handleClick}
        >
          {image ? <MuiDelete mr={2} /> : <MuiCloudUpload mr={2} />}
          {image ? 'Remove' : 'Upload'}
        </MuiButton>
        {onSubmit && (
          <MuiButton
            variant="contained"
            color="primary"
            component="span"
            mb={2}
            style={{ marginLeft: '10px' }}
            onClick={onSubmit}
            disabled={!image || processing}
          >
            {processing ? (
              <CircularProgress size={15} style={{ marginRight: '10px' }} />
            ) : (
              <MuiSave mr={2} />
            )}
            Save
          </MuiButton>
        )}
      </label>
    </div>
  );
};

export default AvatarUpload;
