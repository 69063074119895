import { Dispatch } from 'redux';
import { SET_USER_TOKEN } from '../../../../../core/redux/types/token-types';
import { MeRepositoryImpl } from '../../../data/repositories/me-repository-impl';
import { MeUsecaseImpl } from '../../../domain/usecases/me-usecase';
import { ME_LOAD_REQUEST, ME_LOAD_SUCCESS, ME_LOAD_FAILURE } from '../types/me-types';

export const getMeAction = (token: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: SET_USER_TOKEN, payload: token });
    dispatch({ type: ME_LOAD_REQUEST });

    try {
      const meRepository = new MeRepositoryImpl();
      const meUsecase = new MeUsecaseImpl(meRepository);

      const result = await meUsecase.GetMe();

      dispatch({ type: ME_LOAD_SUCCESS, payload: result });
    } catch (error) {
      dispatch({ type: ME_LOAD_FAILURE, error });
    }
  };
};

export const refreshMeAction = () => {
  return async (dispatch: Dispatch) => {
    try {
      const meRepository = new MeRepositoryImpl();
      const meUsecase = new MeUsecaseImpl(meRepository);

      const result = await meUsecase.GetMe();

      dispatch({ type: ME_LOAD_SUCCESS, payload: result });
    } catch (error) {
      dispatch({ type: ME_LOAD_FAILURE, error });
    }
  };
};
