import { Dispatch } from 'redux';
import {
  LOADING_FAILURE,
  LOADING_REQUEST,
  LOADING_SUCCESS
} from '../../../../../core/redux/types/loading-types';
import { BranchRepositoryImpl } from '../../../data/repositories/branch-repository-impl';
import { BranchUsecaseImpl } from '../../../domain/usecases/branch-usecase';
import {
  BRANCH_LOAD_FAILURE,
  BRANCH_LOAD_REQUEST,
  BRANCH_LOAD_SUCCESS
} from '../types/branch-types';
import {
  BRANCHES_LOAD_FAILURE,
  BRANCHES_LOAD_REQUEST,
  BRANCHES_LOAD_SUCCESS
} from '../types/branches-types';

export const getBranchesAction = () => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: BRANCHES_LOAD_REQUEST });

    try {
      const branchRepository = new BranchRepositoryImpl();
      const branchUsecase = new BranchUsecaseImpl(branchRepository);

      const result = await branchUsecase.GetBranches();

      dispatch({ type: BRANCHES_LOAD_SUCCESS, payload: result });
    } catch (error) {
      dispatch({ type: BRANCHES_LOAD_FAILURE, error });
    }
  };
};

export const getBranchAction = (branch_id: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: BRANCH_LOAD_REQUEST });

    try {
      const branchRepository = new BranchRepositoryImpl();
      const branchUsecase = new BranchUsecaseImpl(branchRepository);

      let result = await branchUsecase.GetBranch(branch_id);

      dispatch({ type: BRANCH_LOAD_SUCCESS, payload: result });
    } catch (error) {
      dispatch({ type: BRANCH_LOAD_FAILURE, error });
    }
  };
};

export const createBranchAction = (
  hub_id: string,
  name: string,
  contact: string,
  store_code: string,
  dashboard_url: string,
  address: string,
  location: any,
  props: any,
  branch_logo?: File | null
) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: BRANCH_LOAD_REQUEST });

    try {
      const branchRepository = new BranchRepositoryImpl();
      const branchUsecase = new BranchUsecaseImpl(branchRepository);

      const result = await branchUsecase.CreateBranch(
        hub_id,
        name,
        contact,
        store_code,
        dashboard_url,
        address,
        location,
        branch_logo
      );

      dispatch({ type: BRANCH_LOAD_SUCCESS, payload: result });
      dispatch({ type: LOADING_SUCCESS });
      props.history.push('/branches');
    } catch (error) {
      dispatch({ type: BRANCH_LOAD_FAILURE, error });
    }
  };
};

export const editBranchAction = (
  branch_id: string,
  name: string,
  store_code: string,
  dashboard_url: string,
  contact: string,
  props: any,
  branch_logo: File | null
) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: LOADING_REQUEST });

    try {
      const branchRepository = new BranchRepositoryImpl();
      const branchUsecase = new BranchUsecaseImpl(branchRepository);

      const result = await branchUsecase.EditBranch(
        branch_id,
        name,
        store_code,
        dashboard_url,
        contact,
        branch_logo
      );

      dispatch({ type: BRANCH_LOAD_SUCCESS, payload: result });
      dispatch({ type: LOADING_SUCCESS });
      props.handleClose();
    } catch (error) {
      dispatch({ type: LOADING_FAILURE, error });
    }
  };
};

export const editBranchAddressAction = (
  branch_id: string,
  address: string,
  location: any,
  props: any
) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: LOADING_REQUEST });

    try {
      const branchRepository = new BranchRepositoryImpl();
      const branchUsecase = new BranchUsecaseImpl(branchRepository);

      const result = await branchUsecase.EditAddress(branch_id, address, location);

      dispatch({ type: BRANCH_LOAD_SUCCESS, payload: result });
      dispatch({ type: LOADING_SUCCESS });
      props.handleClose();
    } catch (error) {
      dispatch({ type: LOADING_FAILURE, error });
    }
  };
};

export const changeBranchHubAction = (branch_id: string, hub_id: string, props: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: LOADING_REQUEST });

    try {
      const branchRepository = new BranchRepositoryImpl();
      const branchUsecase = new BranchUsecaseImpl(branchRepository);

      const result = await branchUsecase.ChangeHub(branch_id, hub_id);

      dispatch({ type: BRANCH_LOAD_SUCCESS, payload: result });
      dispatch({ type: LOADING_SUCCESS });
      props.handleClose();
    } catch (error) {
      dispatch({ type: LOADING_FAILURE, error });
    }
  };
};

export const editPaymentFields = (
  branch_id: string,
  merchant_id: string,
  acquirer_id: string,
  acquirer_name: string,
  props: any
) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: LOADING_REQUEST });

    try {
      const branchRepository = new BranchRepositoryImpl();
      const branchUseCase = new BranchUsecaseImpl(branchRepository);
      const result = await branchUseCase.EditPaymentFields(
        branch_id,
        merchant_id,
        acquirer_id,
        acquirer_name
      );

      dispatch({ type: BRANCH_LOAD_SUCCESS, payload: result });
      dispatch({ type: LOADING_SUCCESS });
      props.showSnackbar();
    } catch (error) {
      dispatch({ type: LOADING_FAILURE, error });
    }
  };
};

export const editBranchThreePlAction = (branch_id: string, partner_id: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: LOADING_REQUEST });

    try {
      const branchRepository = new BranchRepositoryImpl();
      const branchUsecase = new BranchUsecaseImpl(branchRepository);
      const result = await branchUsecase.EditBranchThreePl(branch_id, partner_id);
      dispatch({ type: BRANCH_LOAD_SUCCESS, payload: result });
      dispatch({ type: LOADING_SUCCESS });
    } catch (error) {
      dispatch({ type: LOADING_FAILURE, error });
    }
  };
};

export const deleteBranchThreePlAction = (branch_id: string, props) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: LOADING_REQUEST });

    try {
      const branchRepository = new BranchRepositoryImpl();
      const branchUsecase = new BranchUsecaseImpl(branchRepository);

      const result = await branchUsecase.DeleteBranchThreePl(branch_id);

      dispatch({ type: BRANCH_LOAD_SUCCESS, payload: result });
      dispatch({ type: LOADING_SUCCESS });
      props.showSnackbar();
    } catch (error) {
      dispatch({ type: LOADING_FAILURE, error });
    }
  };
};
